import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useFormik } from 'formik';
import { getTextField } from '../../../../utils/forms';
import CustomTextField from '../../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../../components/forms/custom-elements/CustomFormLabel';
import { createCargo, createCargoOrder } from '../../../../api/cargoia';
import DocumentView from './DocumentView';
import { createCargoBack } from '../utils/createCargoBack';

const LoadForm = ({ listValues, load, loadIA, loads, setLoads, setOpenDetail, setShowDetails }) => {
  const [t, i18] = useTranslation();

  const [searchBoxA, setSearchBoxA] = useState();
  const [searchBoxB, setSearchBoxB] = useState();
  const [pointA, setPointA] = useState(null)
  const [pointB, setPointB] = useState(null)

  const tiposCostos =
    listValues?.favorites?.find((f) => f.idOtraEmpresa === loadIA?.idEmpresa)?.contacto
      .costosFollowGo || [];
  const nombresCostos = [
    { nombre: 'IMPUESTO_AL_CHEQUE', label: 'Impuesto al Cheque' },
    { nombre: 'INGRESOS_BRUTOS', label: 'Ingresos Brutos' },
    { nombre: 'GASTOS_DE_ENVIO', label: 'Gastos de Envío' },
  ];
  const onLoad = (ref, id) => {
    if (id === 'origen') {
      setSearchBoxA(ref);
    } else {
      setSearchBoxB(ref);
    }
  };

  const handleKeyPress = (event) => {
    const key = event.key.toLowerCase();
    if (key === '-' || key === 'e') {
      event.preventDefault();
    }
  };

  const calculoSubtotal = (valor) => {
    let subtotal = 0;
    for (let i = 0; i < tiposCostos.length; i++) {
      if (tiposCostos[i].porcentaje) subtotal += valor * (tiposCostos[i].porcentaje / 100);
      else subtotal += tiposCostos[i].monto;
    }
    return subtotal;
  };

  const getKmTotal = () => {
    if (window?.google?.maps) {
      const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
        pointA,
        pointB,
      );
      const kmTotalCarga = Math.round(distanceInMeters * 0.001).toLocaleString('es-AR');
      return kmTotalCarga;
    }
    return 0
  };


  const formik = useFormik({
    initialValues: {
      origen: load?.ubicacionDesde?.direccion || '',
      latitudOrigen: load?.ubicacionDesde?.latitud || 0,
      longitudOrigen: load?.ubicacionDesde?.longitud || 0,
      destino: load?.ubicacionHasta?.direccion || '',
      latitudDestino: load?.ubicacionHasta?.latitud || 0,
      longitudDestino: load?.ubicacionHasta?.longitud || 0,
      tipoCamion: load?.idTiposCamion || [],
      tipoRemolque: load?.idTiposRemolque || [],
      monto: load?.monto || '',
      monedaId: load?.idMoneda || '',
      condicionesDePago: load?.idCondicionesDePago || [],
      tipoServicio: load?.tipoServicio || 'INFORMATICO',
      tipoCarga: load?.idTipoCarga || '',
      cantidadPallets: load?.cantidadPallet || 0,
      cargaCompleta: load?.cargaCompleta || false,
      fechaDeCarga: load?.fechaDeCarga ? new Date(load?.fechaDeCarga) : '',
      fechaDescarga: load?.fechaDescarga ? new Date(load?.fechaDescarga) : '',
      peso: load?.peso || 0,
      producto: load?.producto || '',
      paisPago: load?.paisPago || '',
      precioSugerido:
        parseFloat(
          parseFloat(load?.valor - calculoSubtotal(load?.valor) - load?.valor * 0.08).toFixed(2),
        ) || 0,
    },
    enableReinitialize: false,
    // validationSchema: ,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const kmTotal = getKmTotal()
      try {
        const newLoad = {
          valor: values.monto,
          valorBruto: values.tarifaBruta,
          simboloMoneda: values.monedaId.simbolo,
          moneda: values.monedaId.id,
          nombreTipoCarga: listValues?.loadTypes.find((tc) => tc.id === values.tipoCarga).nombre,
          tipoCarga: values.tipoCarga || load?.pedidos[0]?.tipoCarga.id || load?.tipoCarga || '',
          cantidadPallet: values.cantidadPallets,
          isCompleto: values.cargaCompleta || false,
          fechaDeCarga: values.fechaDeCarga,
          fechaDescarga: values.fechaDescarga,
          tiposCamion: values.tipoCamion.map((item) => item),
          tiposRemolque: values.tipoRemolque.map((item) => item.id),
          tipoEmbalaje:values.tipoEmbalaje.id || values.tipoEmbalaje,
          condicionesDePago: values.condicionesDePago,
          observacionPago: values.observacionPago,
          porcentajeUtilidad: parseFloat(
            parseFloat(
              ((values.monto - calculoSubtotal(values.monto)) /
                Number(values.precioSugerido)) *
                100 -
                100,
            ).toFixed(2),
          ),
          utilidad: values.monto - values.precioSugerido,
          montoTransportista:values.precioSugerido,
          impuestos: values.impuestosMap,
          pesoTotal: values.peso,
          producto: values.producto,
          tipoServicio: values.tipoServicio,
          paisPago: values.paisPago,
          empresaCreadora: loadIA?.idEmpresa,
          ubicacionHasta: {
            direccion: values.destino,
            latitud: values.latitudDestino,
            longitud: values.longitudDestino,
          },
          ubicacionDesde: {
            direccion: values.origen,
            latitud: values.latitudOrigen,
            longitud: values.longitudOrigen,
          },
          kmTotales: kmTotal
        };
        const { data } = await createCargoOrder(newLoad);
        const formData = new FormData();

        formData.append('id', loadIA.id);
        
        formData.append('montoActual', values.monto);
        formData.append('montoACobrar', values.precioSugerido);
        formData.append('fecha', new Date(loadIA.fecha));
        formData.append('origen', values.origen);
        formData.append('destino', values.destino);
        formData.append('idEmpresa', loadIA.idEmpresa);
        formData.append('nombreEmpresa', loadIA.nombreEmpresa);
        formData.append('idContacto', loadIA.idContacto);
        formData.append('idConversacion', loadIA.idConversacion);
        
        formData.append('mensajeTranscripto', loadIA.mensajeTranscripto);
        formData.append('pathArchivos', loadIA.pathArchivos);
        formData.append('probabilidadDeRetorno', loadIA.probabilidadDeRetorno);
        formData.append('porcentajeFollowGo', loadIA.porcentajeFollowGo);

        try {
          if(loadIA.publicacionDirecta && newLoad.porcentajeUtilidad > 4){
            const nuevaCarga = await createCargoBack(newLoad)
            formData.append('idCarga', nuevaCarga.id);
            formData.append('publicada', true);
            formData.append('aceptaCliente', true);
            formData.append('estado', 'PUBLICADA');
            formData.append('propEnviada', true)
          }
        } catch (error) {
          console.log(error)
          formData.append('idCarga', data.id);
          formData.append('publicada', loadIA.publicada);
          formData.append('aceptaCliente', loadIA.aceptaCliente);
          formData.append('estado', 'GENERADA');
          formData.append('propEnviada', false)
        }
        if(newLoad.porcentajeUtilidad < 4)
          setShowDetails(newLoad)
        const { data: newLoadIA } = await createCargo(formData);
        setLoads(loads.map((l) => (l.id === loadIA.id ? newLoadIA : l)));
        setStatus({ success: true });
        setOpenDetail(false)
      } catch (err) {
        console.log(err);
        setStatus({ success: false });
        setErrors({ submit: err.message });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onPlacesChanged = (id) => {
    const places = id === 'origen' ? searchBoxA.getPlaces() : searchBoxB.getPlaces();

    const locationResult = {
      lat: places[0]?.geometry?.location?.lat() || 0,
      lng: places[0]?.geometry?.location?.lng() || 0,
    };

    if (id === 'origen') {
      formik.setFieldValue('latitudOrigen', locationResult.lat);
      formik.setFieldValue('longitudOrigen', locationResult.lng);
      setPointA(locationResult);
    } else {
      formik.setFieldValue('latitudDestino', locationResult.lat);
      formik.setFieldValue('longitudDestino', locationResult.lng);
      setPointB(locationResult);
    }
    formik.setFieldValue(id, places[0].formatted_address);
  };

  const handleChageDate = (property, value) => {
    formik.setFieldValue(property, value);
  };

  const countPallet = () => {
    const palletTypes = listValues.palletType.map((item) => item.nombre);
    let count = 0;
    for (let i = 0; i < load?.pedidos[0]?.itemPedido.length; i++) {
      for (let j = 0; j < palletTypes.length; j++) {
        count += load?.pedidos[0]?.itemPedido[i]?.pallet[palletTypes[j]] || 0;
      }
    }
    return count;
  };

  const valorCosto = (valor, costo) => {
    if (costo.porcentaje) {
      return `${costo.porcentaje}% ${valor * (costo.porcentaje / 100)}`;
    }
    return costo.monto;
  };

  useEffect(() => {
    const fechaCarga = load?.periodoDeCarga
      ? new Date(load.periodoDeCarga.fechaInicio)
      : load?.fechaDeCarga
      ? new Date(load?.fechaDeCarga)
      : '';
    const fechaDescarga = load?.periodoDeDescarga
      ? new Date(load.periodoDeDescarga.fechaInicio)
      : load?.fechaDescarga
      ? new Date(load?.fechaDescarga)
      : '';
    formik.setValues({
      origen: load?.ubicacionDesde?.direccion || '',
      latitudOrigen: load?.ubicacionDesde?.latitud || 0,
      longitudOrigen: load?.ubicacionDesde?.longitud || 0,
      destino: load?.ubicacionHasta?.direccion || '',
      latitudDestino: load?.ubicacionHasta?.latitud || 0,
      longitudDestino: load?.ubicacionHasta?.longitud || 0,
      tipoCamion: load?.tipoCamion?.map((tp) => tp.id) || load?.tiposCamion || [],
      tipoRemolque: load?.tipoRemolque?.map((tr) => tr.id) || load?.tiposRemolque || [],
      monto: load?.valor || '',
      monedaId: listValues.currency.find((item) => item.simbolo === load?.moneda) || '',
      condicionesDePago: load?.condicionesDePago?.map((cdp) => (cdp.id ? cdp.id : cdp)) || [],
      impuestos: load?.impuestos
        ? Object.entries(load?.impuestos).map(([clave, valor]) => ({
            id: clave,
            porcentaje: valor,
          }))
        : [],
      tipoServicio: load?.tipoServicio || 'INFORMATICO',
      tipoCarga: (load?.pedidos ? load?.pedidos[0]?.tipoCarga.id : load?.tipoCarga) || '',
      cantidadPallets: (load?.pedidos ? countPallet() : load?.cantidadPallet) || 0,
      cargaCompleta: load?.cargaCompleta || false,
      fechaDeCarga: fechaCarga,
      fechaDescarga,
      tipoEmbalaje:load?.pedidos ? load?.pedidos[0]?.itemPedido[0]?.tipoEmbalaje : load?.tipoEmbalaje || '',
      peso: load?.pesoTotal || 0,
      producto: load?.pedidos ? load?.pedidos[0]?.itemPedido[0]?.producto : load?.producto || '',
      paisPago: load?.paisPago || '',
      precioSugerido:
        parseFloat(
          parseFloat(load?.valor - (calculoSubtotal(load?.valor) + load?.valor * 0.08)).toFixed(2),
        ) || 0,
    });
    setPointA({
      lat: load?.ubicacionDesde?.latitud || 0,
      lng: load?.ubicacionDesde?.longitud || 0,
    });
    setPointB({
      lat: load?.ubicacionHasta?.latitud || 0,
      lng: load?.ubicacionHasta?.longitud || 0,
    });
  }, [load]);

  function obtenerNombreArchivo(rutaArchivo) {
    const archivos = [];
    if (rutaArchivo) {
      for (let i = 0; i < rutaArchivo.length; i++) {
        const url = rutaArchivo[i];
        const indiceUltimaBarra = Math.max(rutaArchivo[i].lastIndexOf('/'));
        if (indiceUltimaBarra === -1) {
          archivos.push({ nombre: rutaArchivo[i], url });
        }
        archivos.push({ nombre: rutaArchivo[i].substring(indiceUltimaBarra + 1), url });
      }
    }
    return archivos;
  }

  const tarifaBruta = (monto, procentajes) => {
    let valor = monto;
    const impuestos = new Map();
    for (let i = 0; i < procentajes.length; i++) {
      valor += monto * (procentajes[i].porcentaje / 100);
      impuestos.set(procentajes[i].id, procentajes[i].porcentaje);
    }
    formik.setFieldValue('tarifaBruta', valor);
    formik.setFieldValue('impuestos', procentajes);
    formik.setFieldValue('impuestosMap', Object.fromEntries(impuestos));
    // return valor;
  };

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography variant="h2" sx={{ fontWeight: 300 }}>
          Archivos:
        </Typography>
        <DocumentView archivos={obtenerNombreArchivo(loadIA?.pathArchivos)} />
      </Grid>
      <Grid xs={12}>
        <Typography variant="h2" sx={{ fontWeight: 300 }}>
          Mensaje transcripto:
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: 100, fontStyle: 'italic' }}>
          {`"${loadIA?.mensajeTranscripto}"`}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Divider sx={{ borderColor: '#ffbf3c', my: '1rem' }}>
          <Chip
            label="Datos de la Carga"
            sx={{ background: '#ffbf3c', color: 'secondary.main', fontSize: '1rem' }}
            size="medium"
          />
        </Divider>
      </Grid>
      <Grid xs={12}>
        <StandaloneSearchBox
          onLoad={(ref) => onLoad(ref, 'origen')}
          onPlacesChanged={() => {
            onPlacesChanged('origen');
          }}
        >
          {getTextField(
            {
              ...{
                id: 'origen',
                label: t('Fleet.Home.New-So.OriginAddres'),
                xs: 12,
                subtitle: 'Origen',
                required: true,
                placeholder: t('Loads.Load-Form.Enter-the-origin-address'),
              },
              xs: 12,
            },
            formik,
            {
              placeholder: t('Loads.Load-Form.Enter-the-origin-address'),
            },
          )}
        </StandaloneSearchBox>
      </Grid>
      <Grid xs={12}>
        <StandaloneSearchBox
          onLoad={(ref) => onLoad(ref, 'destino')}
          onPlacesChanged={() => {
            onPlacesChanged('destino');
          }}
        >
          {getTextField(
            {
              ...{
                id: 'destino',
                label: t('Fleet.Home.New-So.DestinationAddres'),
                xs: 12,
                subtitle: 'Destino',
                required: true,
                placeholder: t('Loads.Load-Form.Enter-the-destination-address'),
              },
              xs: 12,
            },
            formik,
            {
              placeholder: t('Loads.Load-Form.Enter-the-destination-address'),
            },
          )}
        </StandaloneSearchBox>
      </Grid>
      <Grid key="tipoCarga" xs={12} alignItems="center">
        <Autocomplete
          id="tipoCarga"
          options={listValues?.loadTypes}
          name="tipoCarga"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={
            formik.values.tipoCarga
              ? listValues?.loadTypes.find((item) => item.id === formik.values.tipoCarga)
              : null
          }
          onChange={(_, newValue) => formik.setFieldValue('tipoCarga', newValue)}
          getOptionLabel={(option) => {
            if (i18.language === 'pt') return `${option?.nombrePortugues}`;
            if (i18.language === 'en') return `${option?.nombreIngles}`;
            return `${option.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>{`${t('Loads.Load-Form.Load-types')} *`}</CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Loads.Load-Form.Load-types')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>
      <Grid xs={12} key="cargaCompleta">
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.cargaCompleta}
                onChange={(e) => formik.setFieldValue('cargaCompleta', e.target.checked)}
              />
            }
            label="Carga completa"
          />
        </FormControl>
      </Grid>
      <Grid xs={4} key="producto">
        <Grid>
          <CustomFormLabel htmlFor="producto">
            {`${t('Fleet.Home.New-So.Product-name')} *`}
          </CustomFormLabel>
        </Grid>
        <Grid>
          <CustomTextField
            id="producto"
            name="producto"
            placeholder={t('Fleet.Home.New-So.Product-name')}
            onBlur={formik.handleBlur}
            value={formik.values.producto}
            onChange={formik.handleChange}
            variant="outlined"
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid xs={4} key="cantidadPallets">
        <Grid>
          <CustomFormLabel htmlFor="cantidadPallets">
            {`${t('Fleet.Home.New-So.Number-of-Pallets')} *`}
          </CustomFormLabel>
        </Grid>
        <Grid>
          <CustomTextField
            id="cantidadPallets"
            name="cantidadPallets"
            placeholder={t('Fleet.Home.New-So.Product-name')}
            onBlur={formik.handleBlur}
            value={formik.values.cantidadPallets}
            onChange={formik.handleChange}
            type="number"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid xs={4} key="peso">
        <Grid>
          <CustomFormLabel htmlFor="peso">{`${t('Fleet.Home.New-So.Weight')} *`}</CustomFormLabel>
        </Grid>
        <Grid>
          <CustomTextField
            id="peso"
            name="peso"
            placeholder={t('Fleet.Home.New-So.Weight')}
            onBlur={formik.handleBlur}
            value={formik.values.peso}
            onChange={formik.handleChange}
            type="number"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid key="tipoEmbalaje" xs={12} alignItems="center">
        <Autocomplete
          id="tipoEmbalaje"
          options={listValues?.packaging}
          name="tipoEmbalaje"
          value={formik.values.tipoEmbalaje ? formik.values.tipoEmbalaje : null}
          isOptionEqualToValue={(option, value) => option.id === `${value}` || option.id === value.id}
          onChange={(_, newValue) => formik.setFieldValue('tipoEmbalaje', newValue)}
          getOptionLabel={(option) => {
            const condicion = listValues?.packaging.find(
              (item) => item.id === option || item.id === option.id,
            );
            if (i18.language === 'pt') return `${condicion?.nombrePortugues}`;
            if (i18.language === 'en') return `${condicion?.nombreIngles}`;
            return `${condicion?.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>{`${t('Fleet.Home.New-So.Type-of-packaging')} *`}</CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Fleet.Home.New-So.Type-of-packaging')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>

      <Grid key="tipoCamion" xs={12} alignItems="center">
        <Autocomplete
          id="tipoCamion"
          options={listValues?.truckTypes}
          name="tipoCamion"
          multiple
          value={formik.values.tipoCamion ? formik.values.tipoCamion : []}
          isOptionEqualToValue={(option, value) => option.id === `${value}`}
          onChange={(_, newValue) => formik.setFieldValue('tipoCamion', newValue)}
          getOptionLabel={(option) => {
            const condicion = listValues?.truckTypes.find(
              (item) => item.id === option || item.id === option.id,
            );
            if (i18.language === 'pt') return `${condicion?.nombrePortugues}`;
            if (i18.language === 'en') return `${condicion?.nombreIngles}`;
            return `${condicion?.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>{`${t('Fleet.Home.New-So.Truck-type')} *`}</CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Fleet.Home.New-So.Truck-type')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>
      <Grid key="tipoRemolque" xs={12} alignItems="center">
        <Autocomplete
          id="tipoRemolque"
          options={listValues?.trailerTypes}
          name="tipoRemolque"
          multiple
          value={formik.values.tipoRemolque ? formik.values.tipoRemolque : []}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id || option.id === value}}
          onChange={(_, newValue) => formik.setFieldValue('tipoRemolque', newValue)}
          getOptionLabel={(option) => {
            const condicion = listValues?.trailerTypes.find((item) => item.id === option.id || item.id === option);
            if (i18.language === 'pt') return `${condicion?.nombrePortugues}`;
            if (i18.language === 'en') return `${condicion?.nombreIngles}`;
            return `${condicion?.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>{`${t('Fleet.Home.New-So.Trailer-type')} *`}</CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Fleet.Home.New-So.Trailer-type')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>
      <Grid item>
        <Grid>
          <CustomFormLabel htmlFor="retiroDateTimeRangeSelector">
            {t('Fleet.Home.New-So.Origin')}
          </CustomFormLabel>
        </Grid>
        <DateTimePicker
          label={t('Loads.Load-Form.Start')}
          value={formik.values.fechaDeCarga || null}
          onChange={(e) => handleChageDate('fechaDeCarga', e)}
          slotProps={<TextField />}
          minDateTime={new Date()}
          format="dd/MM/yyyy HH:mm"
        />
      </Grid>
      <Grid item>
        <Grid>
          <CustomFormLabel htmlFor="entregaDateTimeRangeSelector">
            {t('Fleet.Home.New-So.Destination')}
          </CustomFormLabel>
        </Grid>
        <DateTimePicker
          label={t('Loads.Load-Form.Finalization')}
          value={formik.values.fechaDescarga || null}
          onChange={(e) => handleChageDate('fechaDescarga', e)}
          slotProps={<TextField sx={{ maxWidth: '250px' }} />}
          minDateTime={new Date() || formik.values.fechaDeCarga}
          format="dd/MM/yyyy HH:mm"
        />
      </Grid>
      <Grid xs={12}>
        <Divider sx={{ borderColor: '#ffbf3c', my: '1rem' }}>
          <Chip
            label="Datos de facturación dador"
            sx={{ background: '#ffbf3c', color: 'secondary.main', fontSize: '1rem' }}
            size="medium"
          />
        </Divider>
      </Grid>
      <Grid xs={12}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          {t('Loads.serivceType')}
        </Typography>

        <RadioGroup
          row
          defaultValue={listValues?.serviceType[0]?.value}
          onChange={(event) => formik.setFieldValue('tipoServicio', event.target.value)}
        >
          {listValues?.serviceType.map((service) => (
            <FormControlLabel
              key={service.value}
              value={service.value}
              control={<Radio />}
              label={service.label}
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid xs={4} key="monto">
        <Grid>
          <CustomFormLabel htmlFor="monto">{`${t('Order.Order-form.net rate')} *`}</CustomFormLabel>
        </Grid>
        <Grid my="1rem">
          <CustomTextField
            id="monto"
            name="monto"
            placeholder={t('Order.Order-form.net rate')}
            onBlur={formik.handleBlur}
            value={formik.values.monto}
            onChange={formik.handleChange}
            type="number"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid key="monedaId" xs={4} alignItems="center">
        <Autocomplete
          id="monedaId"
          options={listValues?.currency}
          name="monedaId"
          value={formik.values.monedaId ? formik.values.monedaId : null}
          isOptionEqualToValue={(option, value) => option.id === `${value.id}`}
          onChange={(_, newValue) => formik.setFieldValue('monedaId', newValue)}
          getOptionLabel={(option) => {
            const condicion = listValues?.currency.find((item) => item.id === option.id);
            if (i18.language === 'pt') return `${condicion?.nombrePortugues}`;
            if (i18.language === 'en') return `${condicion?.nombreIngles}`;
            return `${condicion?.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>{`${t('Order.Order-form.Currency')} *`}</CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Order.Order-form.Select-currency')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>
      <Grid key="paisPago" xs={4} alignItems="center">
        <Autocomplete
          id="paisPago"
          options={listValues?.country}
          name="pais"
          value={formik.values.paisPago ? formik.values.paisPago : null}
          isOptionEqualToValue={(option, value) => option.nombre === value}
          onChange={(_, newValue) => formik.setFieldValue('paisPago', newValue)}
          getOptionLabel={(option) => {
            const condicion = listValues?.country.find(
              (item) => item.nombre === option || item.nombre === option.nombre,
            );

            if (i18.language === 'pt') return `${condicion?.nombrePortugues}`;
            if (i18.language === 'en') return `${condicion?.nombreIngles}`;
            return `${condicion?.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>{`${t('Order.Order-form.Payment-country')} *`}</CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Order.Order-form.Select-payment-country')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>
      <Grid key="impuestos" xs={12} alignItems="center">
        <Autocomplete
          id="impuestos"
          options={listValues?.taxes}
          name="impuestos"
          multiple
          value={formik.values.impuestos ? formik.values.impuestos : []}
          onChange={(_, newValue) => tarifaBruta(formik.values.monto, newValue)}
          getOptionLabel={(option) => {
            const condicion = listValues?.taxes.find(
              (item) => item.id === `${option}` || item.id === `${option.id}`,
            );
            if (i18.language === 'pt') return `${condicion?.nombrePortugues}`;
            if (i18.language === 'en') return `${condicion?.nombreIngles}`;
            return `${condicion?.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>
                  {t('Order.Order-form.impuesto')}{' '}
                  {formik.values.impuestos !== 'subasta' ? '*' : ''}
                </CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Order.Order-form.Select-tax')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>
      <Grid container xs={12} sx={{ mt: 2 }}>
        <Typography xs={6} variant="h3" sx={{ fontWeight: 300 }}>
          Tarifa bruta (Con impuestos):
        </Typography>
        {formik.values.monto && formik.values.impuestos && formik.values.monedaId && (
          <Typography
            xs={6}
            variant="h3"
            sx={{ fontWeight: 100, fontStyle: 'italic', ml: '0.5rem' }}
          >
            {`${formik.values.monedaId?.simbolo} ${formik.values.tarifaBruta || formik.values.monto}`}
          </Typography>
        )}
      </Grid>
      <Grid key="condicionesDePago" xs={12} alignItems="center">
        <Autocomplete
          id="condicionesDePago"
          options={listValues?.paymentConditions}
          name="condicionesDePago"
          multiple
          value={formik.values.condicionesDePago ? formik.values.condicionesDePago : []}
          onChange={(_, newValue) => formik.setFieldValue('condicionesDePago', newValue)}
          getOptionLabel={(option) => {
            const condicion = listValues?.paymentConditions?.find(
              (item) => item?.id === `${option}` || item?.id === `${option?.id}`,
            );
            if (i18.language === 'pt') return `${condicion?.nombrePortugues}`;
            if (i18.language === 'en') return `${condicion?.nombreIngles}`;
            return `${condicion?.nombre}`;
          }}
          renderInput={(params) => (
            <>
              <Grid my="1rem">
                <CustomFormLabel>
                  {t('Order.Order-form.Payment-condition')}{' '}
                  {formik.values.condicionesDePago !== 'subasta' ? '*' : ''}
                </CustomFormLabel>
              </Grid>
              <CustomTextField
                {...params}
                placeholder={t('Order.Order-form.Select-payment-method')}
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            </>
          )}
        />
      </Grid>
      <Grid xs={12} key="observacionPago">
        <Grid>
          <CustomFormLabel htmlFor="observacionPago">
            {`${t('Order.payment observation')} *`}
          </CustomFormLabel>
        </Grid>
        <Grid>
          <CustomTextField
            id="observacionPago"
            name="observacionPago"
            placeholder={t('Order.payment observation')}
            onBlur={formik.handleBlur}
            value={formik.values.observacionPago}
            onChange={formik.handleChange}
            type="text"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider sx={{ borderColor: '#ffbf3c', my: '1rem' }}>
          <Chip
            label="Indice de costos"
            sx={{ background: '#ffbf3c', color: 'secondary.main', fontSize: '1rem' }}
            size="medium"
          />
        </Divider>
      </Grid>
      <Grid xs={12} container>
        {tiposCostos.map((costo, index) => (
          <Grid xs={12} key={costo.tipoCosto}>
            <Grid>
              <CustomFormLabel htmlFor={costo.tipoCosto}>{`${
                nombresCostos.find((item) => item.nombre === costo.tipoCosto).label
              }  ${valorCosto(Number(formik.values.monto), costo)} ${
                formik.values.monedaId?.simbolo
              }`}</CustomFormLabel>
            </Grid>
          </Grid>
        ))}
        <Grid>
          <CustomFormLabel>{`Subtotal:  ${calculoSubtotal(formik.values.monto)} ${
            formik.values.monedaId?.simbolo
          }`}</CustomFormLabel>
        </Grid>
      </Grid>
      <Grid container xs={6} key="precioSugerido">
        <Grid xs={12}>
          <CustomFormLabel htmlFor="precioSugerido">
            {`${t('Order.Order-form.PrecioSugerido')} *`}
          </CustomFormLabel>
        </Grid>
        <Grid xs={6}>
          <CustomTextField
            id="precioSugerido"
            name="PrecioSugerido"
            placeholder={t('Order.Order-form.PrecioSugerido')}
            onBlur={formik.handleBlur}
            value={formik.values.precioSugerido || ''}
            onChange={(event) => formik.setFieldValue('precioSugerido', event.target.value)}
            type="text"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
        <Grid xs={6}>
          <CustomFormLabel sx={{ ml: '0.5rem' }}>{`Margen de utilidad:${parseFloat(
            parseFloat(
              ((formik.values.monto - calculoSubtotal(formik.values.monto)) /
                Number(formik.values.precioSugerido)) *
                100 -
                100,
            ).toFixed(2),
          )}%`}</CustomFormLabel>
        </Grid>
      </Grid>

      <Grid xs={12} container>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mt: '0.5rem' }}
          onClick={formik.handleSubmit}
        >
          <Typography>{t('Button.Confirm')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default LoadForm;
