/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';
import {
  Card,
  Dialog,
  Divider,
  Box,
  Grid,
  Button,
  Container,
  Typography,
  Chip,
  useMediaQuery,
  useTheme,
  DialogContent,
  DialogActions,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WarningIcon from '@mui/icons-material/Warning';
import SellIcon from '@mui/icons-material/Sell';
import { useSnackbar } from 'notistack';

import { aceptarContraoferta, rechazarContraoferta } from '../../../api/contraoferta';

import useAuth from '../../../hooks/useAuth';

import Breadcrumb from '../../../layouts/fleet-layout/breadcrumb/Breadcrumb';
import PageContainer from '../../../components/container/PageContainer';
import Spinner from '../../spinner/Spinner';
import ShowMsgAndAction from './ShowMsgAndAction';
import DetalleMap from './cargas/components/DetalleMap';
import ModalLoadDetails from '../../../components/modals/ModalLoadDetails';
import Chat from './cargas/DetalleDeCarga/Chat';
import ResponderUna from './cargas/DetalleDeCarga/ResponderUna';
import ShowOffers from './cargas/DetalleDeCarga/ShowOffers';
import LabelWithIcon from '../../../components/LabelWithIcon';
import LoadCard from '../../../components/LoadCard';
import CreateFreeAccountModal from '../components/CreateFreeAccountModal';
import { FRONT_URL } from '../../../config';
import Carousel from '../../cargo/pedidos/components/Carousel';

const dialogBase = {
  open: false,
  title: '',
  msg: '',
  handleConfirm: () => {},
  handleCancel: () => {},
};

const OrderView = ({
  children,
  infoSelectd,
  serverError,
  noOrderError,
  setServerError,
  reloadOrder,
}) => {
  const navigate = useNavigate();
  const [t, i18next] = useTranslation();
  const [choosenLoad, setChoosenLoad] = useState(null);
  const [openModalViewLoads, setOpenModalViewLoads] = useState(false);
  const [openModalFreeAccount, setOpenModalFreeAccount] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [aQuestion, setAquestion] = useState();
  const [notificar, setNotificar] = useState(true);

  const [dialogOptions, setDialogOptions] = useState({ ...dialogBase });
  const [loading, setLoading] = useState(false);

  const { authenticatedData, isAuthenticated } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = (key) => {
    closeSnackbar(key);
  };

  const theme = useTheme();
  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'));
  const chipStyle = { fontSize: '12px', letterSpacing: '1px', color: theme.palette.text.secondary };

  const BCrumb = [
    {
      to: '/',
      title: 'Home',
    },
    {
      title: t('TableHeader.OrderDetail'),
    },
  ];

  function sortAnswers(x, y) {
    const firstDate = new Date(x.alta);
    const secondDate = new Date(y.alta);

    if (firstDate < secondDate) return -1;
    if (firstDate > secondDate) return 1;
    return 0;
  }

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
      onClick: () => handleCloseSnackbar(),
    });
  };

  const handleCopyToClipboard = async (value) => {
    const value1 = `${FRONT_URL}/marketplace/detalle/${value}`;
    await navigator.clipboard.writeText(value1);
    showMessage(t('Configuration.LinkSucces'), 'success');
  };

  const handleCloseModal = () => {
    setModalAction(false);
  };

  function navigateToOrderList() {
    navigate('/marketplace/inicio');
  }

  function retryLoadOrder() {
    setServerError(false);
    reloadOrder();
  }

  async function handleResignOffer(offer) {
    setLoading(true);
    try {
      await rechazarContraoferta(offer.id);
      reloadOrder();
      showMessage(t('Status.OfertaRechazada'), 'success');
    } catch (error) {
      console.log(error);
      showMessage(error?.response?.data?.error || t('Configuration.ServerError'), 'error');
    } finally {
      setLoading(false);
      setDialogOptions({ ...dialogBase });
    }
  }

  function handleConfirmResignOffer(offer) {
    setDialogOptions({
      open: true,
      title: t('Status.RechazarOferta'),
      msg: `¿ Desea rechazar la oferta de ${offer.creador.nombre} por ${offer.valor} ${
        offer?.moneda.nombre || 'ARS'
      } ${offer.incluyeIva ? ' (Incluye IVA)' : ''} ?`,
      handleConfirm: () => handleResignOffer(offer),
      handleCancel: () => setDialogOptions({ ...dialogBase }),
    });
  }

  async function handleAcceptOffer(offer) {
    setLoading(true);
    try {
      const notificacion = document.getElementById('notificarAOfrecidos').checked;
      await aceptarContraoferta(offer.id, notificacion);
      showMessage(t('Status.OfertaAceptada'), 'success');
      reloadOrder();
    } catch (error) {
      console.log(error);
      showMessage(error?.response?.data?.error || t('Configuration.ServerError'), 'error');
    } finally {
      setLoading(false);
      setDialogOptions({ ...dialogBase });
    }
  }

  function handleConfirmAcceptOffer(offer) {
    setDialogOptions({
      open: true,
      title: t('Status.AceptarOferta'),
      msg: `¿ Desea aceptar la oferta de ${offer.creador.nombre} por ${offer.valor} ${
        offer?.moneda.nombre || 'ARS'
      } ${offer.incluyeIva ? ' (Incluye IVA)' : ''} ?`,
      handleConfirm: () => handleAcceptOffer(offer),
      handleCancel: () => setDialogOptions({ ...dialogBase }),
    });
  }

  function handleViewLoadDetails(loadToView) {
    setChoosenLoad(loadToView);
    setOpenModalViewLoads(true);
  }

  const handleClickOpen = () => {
    setOpenModalFreeAccount(true);
  };

  const subtitulefechaInicio = (periodo) => {
    if (periodo?.fechaInicio) {
      const fechaObjeto = new Date(periodo.fechaInicio);

      const fechaFormateada = fechaObjeto.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC', // Ignora la zona horaria
      });
      return fechaFormateada;
    }
    return format(new Date(periodo.inicio), 'dd/MM/yyyy');
  };

  const subtitulefechaFin = (periodo) => {
    if (periodo?.fechaFin) {
      const fechaObjeto = new Date(periodo?.fechaFin);
      const fechaFormateada = fechaObjeto.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC', // Ignora la zona horaria
      });
      return fechaFormateada;
    }
    return format(new Date(periodo?.finalizacion), 'dd/MM/yyyy');
  };

  const subtituleHoraInicio = (periodo) => {
    if (periodo?.fechaInicio) {
      const MomentDate = moment(new Date(periodo?.fechaInicio), 'dd/MM/yyyy HH:mm');
      return MomentDate.format('HH:mm');
    }
    return format(new Date(periodo?.horaInicio), 'HH:mm');
  };

  const subtituleHoraFin = (periodo) => {
    if (periodo?.fechaFin) {
      const MomentDate = moment(new Date(periodo?.fechaFin), 'dd/MM/yyyy HH:mm');
      return MomentDate.format('HH:mm');
    }
    return format(new Date(periodo?.horaFinalizacion), 'HH:mm');
  };

  useEffect(() => {}, []);

  if (!infoSelectd) {
    return (
      <Container maxWidth={false}>
        {!noOrderError && !serverError && <Spinner />}
        {noOrderError && (
          <ShowMsgAndAction
            msg={noOrderError}
            subMsg={t('Button.Back2')}
            icon={<ContentPasteOffIcon fontSize="large" />}
            handleOnClick={navigateToOrderList}
          />
        )}
        {serverError && (
          <ShowMsgAndAction
            msg={serverError}
            subMsg={t('Button.Retry')}
            icon={<WifiOffIcon fontSize="large" />}
            handleOnClick={retryLoadOrder}
          />
        )}
      </Container>
    );
  }

  const vehicle = {
    nombre: (() => {
      if (infoSelectd?.tipoCamion && infoSelectd.tipoCamion.length > 0) {
        return t('Types.Camion');
      }
      if (infoSelectd?.tipoRemolque && infoSelectd.tipoRemolque.length > 0) {
        return t('Types.Remolque');
      }
      return t('Types.Desconocido');
    })(),
  };

  const subType = [
    ...(infoSelectd?.tiposCamion || infoSelectd?.tipoCamion || []),
    ...(infoSelectd?.tiposRemolque || infoSelectd?.tipoRemolque || []),
  ];

  const getCondicion = () => {
    let condicion = '';
    infoSelectd.condicionesDePago.forEach((c) => {
      if (condicion === '') {
        condicion =
          i18next.language === 'es'
            ? c.nombre
            : i18next.language === 'pt'
            ? c.nombrePortugues
            : c.nombreIngles;
      } else {
        condicion = `${condicion} - ${
          i18next.language === 'es'
            ? c.nombre
            : i18next.language === 'pt'
            ? c.nombrePortugues
            : c.nombreIngles
        }`;
      }
    });
    return condicion;
  };

  console.clear();
  console.log(infoSelectd);

  return (
    <PageContainer title="Carga" description="Carga">
      <Breadcrumb items={BCrumb} />

      <Card sx={{ p: 0 }}>
        <Box
          sx={{
            bgcolor: infoSelectd.estadoCarga === 'PAUSADA' ? 'danger.main' : 'primary.main',
            px: 4,
            py: 3,
          }}
        >
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" mb={1}>
            <Typography variant="h2" lineHeight="24px" mr={2} color="white">
              {t('Loads.Planning_View.Order')}
              {infoSelectd.nroCarga}
            </Typography>
            <SellIcon
              style={{
                color: 'white',
                fontSize: '36px',
              }}
            />
          </Box>
        </Box>

        {infoSelectd.estadoCarga === 'PAUSADA' && (
          <Box
            sx={{
              backgroundColor: theme.palette.danger.main,
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <WarningIcon
              style={{
                color: 'white',
                fontSize: '36px',
              }}
            />

            <Typography
              variant="h3"
              letterSpacing="2px"
              textAlign="center"
              m={1}
              color={theme.palette.danger.light}
            >
              {t('Status.PublicacionPausada').toUpperCase()}
            </Typography>

            <WarningIcon
              style={{
                color: 'white',
                fontSize: '36px',
              }}
            />
          </Box>
        )}

        <Box p={4}>
          <Grid container spacing={2} mb={4}>
            {infoSelectd.empresaTransportadora && isAuthenticated && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon
                  title={t('TableHeader.Transport')}
                  subTitle={infoSelectd?.empresaTransportadora}
                  featherIcon="home"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={t('TableHeader.OrderStatus')}
                subTitle={t(`Status.${infoSelectd?.estadoCargaNombre}`).toUpperCase()}
                align="left"
              />
            </Grid>

            {infoSelectd?.tipoDeViajeNombre && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon
                  title={t('Order.TravelType')}
                  subTitle={t(`Types.${infoSelectd?.tipoDeViajeNombre}`)}
                  featherIcon="send"
                  align="left"
                />
              </Grid>
            )}

            {isAuthenticated && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon
                  title={t('Fleet.Home.Service-Orders.Creative-company')}
                  subTitle={infoSelectd?.empresaCreadora || '-'}
                  featherIcon="send"
                  align="left"
                />
              </Grid>
            )}

            {infoSelectd?.descripcion && (
              <Grid item xs={12} sm={12}>
                <LabelWithIcon
                  title={t('TableHeader.Description')}
                  subTitle={infoSelectd?.descripcion}
                  featherIcon="message-circle"
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} mb={2}>
            {infoSelectd.periodoDeCarga && infoSelectd.ubicacionDesde && (
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={1}>
                    <Divider textAlign="left">
                      <Chip label={t('TableHeader.Origin')} sx={chipStyle} />
                    </Divider>
                  </Grid>
                  {infoSelectd.periodoDeCarga && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <LabelWithIcon
                            title={t('TableHeader.Start')}
                            subTitle={`${subtitulefechaInicio(infoSelectd.periodoDeCarga)} 
                            ${subtituleHoraInicio(infoSelectd.periodoDeCarga)}hs`}
                            featherIcon="calendar"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <LabelWithIcon
                            title={t('TableHeader.End')}
                            subTitle={`${subtitulefechaFin(
                              infoSelectd.periodoDeCarga,
                            )} ${subtituleHoraFin(infoSelectd.periodoDeCarga)}hs`}
                            featherIcon="calendar"
                            align="left"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {infoSelectd.ubicacionDesde.direccion && (
                    <Grid item xs={12}>
                      <LabelWithIcon
                        title={t('TableHeader.Address')}
                        subTitle={infoSelectd.ubicacionDesde.direccion}
                        featherIcon="map-pin"
                      />
                    </Grid>
                  )}
                  {infoSelectd.ubicacionDesde.observacion && (
                    <Grid item xs={12}>
                      <LabelWithIcon
                        title={t('TableHeader.Observations')}
                        subTitle={infoSelectd.ubicacionDesde.observacion}
                        featherIcon="clipboard"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            {infoSelectd.periodoDeDescarga && infoSelectd.ubicacionHasta && (
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={1}>
                    <Divider textAlign="right">
                      <Chip label={t('TableHeader.Destination')} sx={chipStyle} />
                    </Divider>
                  </Grid>
                  {infoSelectd.periodoDeDescarga && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <LabelWithIcon
                            title={t('TableHeader.Start')}
                            subTitle={`${subtitulefechaInicio(infoSelectd.periodoDeDescarga)} 
                            ${subtituleHoraInicio(infoSelectd.periodoDeDescarga)}hs`}
                            featherIcon="calendar"
                            align="right"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <LabelWithIcon
                            title={t('TableHeader.End')}
                            subTitle={`${subtitulefechaFin(infoSelectd.periodoDeDescarga)} 
                            ${subtituleHoraFin(infoSelectd.periodoDeDescarga)}hs`}
                            featherIcon="calendar"
                            align="right"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {infoSelectd.ubicacionHasta.direccion && (
                    <Grid item xs={12}>
                      <LabelWithIcon
                        title={t('TableHeader.Address')}
                        subTitle={infoSelectd.ubicacionHasta.direccion}
                        featherIcon="map-pin"
                        align="right"
                      />
                    </Grid>
                  )}
                  {infoSelectd.ubicacionHasta.observacion && (
                    <Grid item xs={12}>
                      <LabelWithIcon
                        title={t('TableHeader.Observations')}
                        subTitle={infoSelectd.ubicacionHasta.observacion}
                        featherIcon="clipboard"
                        align="right"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>

          <DetalleMap detalle={infoSelectd} />

          {subType && (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Divider>
                  <Chip label={t('Configuration.Caracteristicas')} sx={chipStyle} />
                </Divider>
              </Grid>
              <Carousel subTypes={subType} infoSelectd={infoSelectd} vehicle={vehicle} />
              {infoSelectd?.caracteristicas && infoSelectd?.caracteristicas.length > 0 && (
                <Grid item xs={12} container direction="column">
                  <LabelWithIcon
                    title={t('Fleet.Home.New-So.Characteristics')}
                    subTitle={infoSelectd?.caracteristicas
                      .reduce((acum, a) => `${acum}, ${t(a)}`, '')
                      .slice(1)}
                    featherIcon="clipboard"
                    sxTitle={{ fontSize: '1.5rem' }}
                    sxSubTitle={{ fontSize: '1.3rem' }}
                  />
                </Grid>
              )}

              {infoSelectd?.accesorios && infoSelectd?.accesorios.length > 0 && (
                <Grid item xs={12} container direction="column">
                  <LabelWithIcon
                    title={t('Order.Accessories')}
                    subTitle={infoSelectd?.accesorios
                      .reduce((acum, a) => `${acum}, ${t(a)}`, '')
                      .slice(1)}
                    featherIcon="paperclip"
                    sxTitle={{ fontSize: '1.5rem' }}
                    sxSubTitle={{ fontSize: '1.3rem' }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {isAuthenticated && infoSelectd.pedidos && (
            <Grid container spacing={4} mb={3}>
              <Grid item xs={12}>
                <Divider>
                  <Chip label={t('Message.LoadsOrder')} sx={chipStyle} />
                </Divider>
              </Grid>
              {isAuthenticated &&
                infoSelectd.pedidos.map((carga, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      display="flex"
                      key={`carga-${carga.id}-${i.toString()}`}
                    >
                      <LoadCard item={carga} handleDetails={() => handleViewLoadDetails(carga)} />
                    </Grid>
                  );
                })}
            </Grid>
          )}

          {isAuthenticated && infoSelectd.estadoCargaNombre === 'Ofertada' && (
            <Grid container mt={2} mb={2}>
              <Grid item xs={12} mb={1}>
                <Divider>
                  <Chip label={t('Order.OfferHistory')} sx={chipStyle} />
                </Divider>
              </Grid>
              {infoSelectd &&
                (infoSelectd.recibirOfertas || infoSelectd.estadoCargaNombre === 'Ofertada') &&
                isAuthenticated && (
                  <Grid xs={12} mb={1}>
                    {infoSelectd.historialOfertas?.length === 0 ? (
                      <Typography variant="h4">{t('Order.NoOffer')}</Typography>
                    ) : (
                      <ShowOffers
                        offers={infoSelectd.historialOfertas}
                        handleResignOffer={handleConfirmResignOffer}
                        handleAcceptOffer={handleConfirmAcceptOffer}
                        authenticatedData={authenticatedData}
                        idEmpresaCreadora={infoSelectd.idEmpresaCreadora}
                      />
                    )}
                  </Grid>
                )}
            </Grid>
          )}

          <Grid container spacing={2} mt={2} mb={2}>
            <Grid item xs={12} mb={1}>
              <Divider>
                <Chip label={t('Message.ConditionsOrder')} sx={chipStyle} />
              </Divider>
            </Grid>
            {!infoSelectd.recibirOfertas ? (
              <>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LabelWithIcon
                    title={t('Order.Value')}
                    subTitle={
                      infoSelectd?.valor ? `${infoSelectd.valor} ${infoSelectd.moneda}` : '---'
                    }
                    featherIcon="dollar-sign"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LabelWithIcon
                    title={t('Order.PaymentCondition')}
                    subTitle={
                      infoSelectd.condicionesDePago !== null &&
                      infoSelectd?.condicionesDePago?.length > 0
                        ? infoSelectd?.condicionesDePago[0].id
                          ? infoSelectd?.condicionesDePago?.map((c) => c.nombre).join(', ')
                          : infoSelectd?.condicionesDePago?.join(', ')
                        : '---'
                    }
                    featherIcon="credit-card"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LabelWithIcon
                    title={t("Order.PaymentCountry")}
                    subTitle={infoSelectd?.paisPago || '---'}
                    featherIcon="flag"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LabelWithIcon
                    title={t('Order.payment observation')}
                    subTitle={infoSelectd.observacionPago || '---'}
                    featherIcon="credit-card"
                  />
                </Grid>

                {infoSelectd?.honorarios && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LabelWithIcon
                      title={t('Order.Honorarios')}
                      subTitle={`${infoSelectd.honorarios} ${infoSelectd.monedaHonorarios}`}
                      featherIcon="dollar-sign"
                    />
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon title={t('Order.SentToAuction')} featherIcon="refresh-ccw" />
              </Grid>
            )}
          </Grid>
          {isAuthenticated && infoSelectd.Checklist && (
            <>
              <Divider
                sx={{
                  backgroundColor: 'primary.dark',
                }}
              />
              <Box sx={{ marginLeft: '20px' }}>
                <h1>CheckList</h1>
                {infoSelectd.Checklist?.map((check, i) => (
                  <Box display="flex">
                    <Box
                      paddingRight={3}
                      sx={{
                        color: 'primary.dark',
                      }}
                    >
                      <h4>{i}</h4>
                    </Box>
                    <Box paddingTop={3}>
                      <text>{check}</text>
                      <p />
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
          {isAuthenticated && (
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} mb={1}>
                <Divider>
                  <Chip label={t('Order.Questions')} sx={chipStyle} />
                </Divider>
              </Grid>
              {infoSelectd?.preguntas?.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="h4">{t('Order.NoQuestions')}</Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  {infoSelectd?.preguntas?.sort(sortAnswers).map((unaPregunta) => {
                    return (
                      <Chat
                        unaPregunta={unaPregunta}
                        setModalAction={setModalAction}
                        setAquestion={setAquestion}
                        authenticatedData={authenticatedData}
                        sortFunction={sortAnswers}
                      />
                    );
                  })}
                </Grid>
              )}
            </Grid>
          )}
          {infoSelectd.plantillas !== null && (
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{}}>
              <Grid item xs={12} mb={1}>
                <Divider>
                  <Chip label={t('Load.plantillasTexto.plantillas')} sx={chipStyle} />
                </Divider>
              </Grid>
              {infoSelectd.plantillas && infoSelectd.plantillas.length > 0 ? (
                infoSelectd.plantillas.map((plantilla, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="left"
                    alignItems="left"
                    key={index}
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      textAlign="left"
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}
                    >
                      <LabelWithIcon title={plantilla.nombre || '-'} featherIcon="align-justify" />
                      <Typography variant="body2" sx={{ marginTop: 1 }}>
                        {plantilla.texto || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign="center" mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    -
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={6}
              align={mdUp ? 'left' : 'center'}
              display="flex"
              alignItems="center"
              justifyContent={mdUp ? 'flex-start' : 'center'}
              mt={4}
            >
              <Button
                size="small"
                fullWidth={!mdUp}
                to={-1}
                component={Link}
                sx={{
                  width: !mdUp ? '100%' : '200px',
                }}
                variant="outlined"
              >
                <Typography variant="h3">{t('Button.Cancel')}</Typography>
              </Button>
            </Grid>

            {isAuthenticated && (
              <Grid
                item
                xs={12}
                md={6}
                align={mdUp ? 'right' : 'center'}
                display="flex"
                alignItems="center"
                justifyContent={mdUp ? 'flex-end' : 'center'}
                mt={4}
              >
                {authenticatedData?.idEmpresa === infoSelectd.idEmpresaCreadora ? (
                  <Button
                    size="small"
                    fullWidth={!mdUp}
                    variant="contained"
                    sx={{
                      width: !mdUp ? '100%' : '200px',
                    }}
                    onClick={() => handleCopyToClipboard(infoSelectd.id)}
                  >
                    <Typography variant="h3">{t('Button.Share')}</Typography>
                  </Button>
                ) : (
                  children
                )}
              </Grid>
            )}
            {!isAuthenticated && (
              <Grid
                item
                xs={12}
                md={6}
                align={mdUp ? 'right' : 'center'}
                display="flex"
                alignItems="center"
                justifyContent={mdUp ? 'flex-end' : 'center'}
                mt={4}
              >
                <Button
                  size="small"
                  fullWidth={!mdUp}
                  variant="contained"
                  sx={{
                    width: !mdUp ? '100%' : '200px',
                  }}
                  onClick={handleClickOpen}
                >
                  <Typography variant="h3">{t('Button.Offer')}</Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Card>

      <ModalLoadDetails
        open={openModalViewLoads}
        handleClose={() => setOpenModalViewLoads(false)}
        load={choosenLoad}
      />

      <Dialog open={modalAction} onClose={handleCloseModal}>
        <ResponderUna
          question={aQuestion}
          onClose={handleCloseModal}
          order={infoSelectd}
          loadOrder={reloadOrder}
          authenticatedData={authenticatedData}
        />
      </Dialog>

      <Dialog
        open={dialogOptions.open}
        onClose={dialogOptions.handleCancel}
        fullWidth
        maxWidth="sm"
        scroll="paper"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          id="alert-dialog-title"
          width={1}
          p={2}
          pt={1}
          pb={1}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box display="flex" alignItems="center">
            {dialogOptions.title === 'Aceptar oferta' ? (
              <FeatherIcon icon="check-circle" color={theme.palette.success.main} />
            ) : (
              <FeatherIcon icon="x-circle" color={theme.palette.error.main} />
            )}

            <Typography variant="h2" ml={1}>
              {dialogOptions.title}
            </Typography>
          </Box>

          <IconButton onClick={dialogOptions.handleCancel} disabled={loading}>
            <FeatherIcon icon="x" />
          </IconButton>
        </Box>

        <DialogContent dividers>
          <Box mx={2} display="flex" alignItems="center">
            <Typography
              id="alert-dialog-description"
              variant="body1"
              sx={{
                whiteSpace: 'pre-line',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '100',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
              }}
            >
              {dialogOptions.msg}
            </Typography>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={notificar}
                id="notificarAOfrecidos"
                onChange={(e) => setNotificar(e.target.checked)}
              />
            }
            label={t('Marketplace.Actions.AcceptOfferHeader')}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={dialogOptions.handleCancel}
            variant="text"
            color="primary"
            size="small"
            sx={{ pr: 2 }}
            disabled={loading}
          >
            <Typography
              variant="h3"
              sx={{ color: !loading ? theme.palette.primary.main : theme.palette.text.disabled }}
            >
              {t('Button.Cancel')}
            </Typography>
          </Button>

          <Button
            onClick={dialogOptions.handleConfirm}
            variant="text"
            color="primary"
            size="small"
            sx={{ pr: 2 }}
            disabled={loading}
          >
            <Typography
              variant="h3"
              sx={{ color: !loading ? theme.palette.primary.main : theme.palette.text.disabled }}
            >
              {t('Button.Confirm')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <CreateFreeAccountModal open={openModalFreeAccount} setOpen={setOpenModalFreeAccount} />
    </PageContainer>
  );
};

export default OrderView;
