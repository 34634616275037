import { useTranslation } from 'react-i18next';

export const initialValuesList = {
  loadTypes: [],
  accessories: [],
  currency: [],
  features: [],
  measurementUnits: [],
  packaging: [],
  paymentConditions: [],
  trailerTypes: [],
  vehicleTypes: [],
  customers: [],
  verifiedTransports: [],
  loads: [],
};

export const BCrumbCreate = (currentModule) => {
  const [t] = useTranslation();

  if (currentModule === true) {
    return [
      {
        to: '/carga/pedidos',
        title: t('Menu.Orders'),
      },
      {
        title: t('New fast load'),
      },
    ];
  }

  return [
    {
      to: '/flota',
      title: t('Fleet.Fleet'),
    },
    {
      title: t('Fleet.New-Service-order.New-Service-Order'),
    },
  ];
};

export const BCrumbEdit = [
  {
    to: '/flota',
    title: '/flota',
  },
  {
    title: 'Editar orden de servicio',
  },
];

export const formItemsGeneral = [
  {
    id: 'tipoCamion',
    label: 'Truck-type',
    xs: 12,
    required: true,
    multiple: true,
  },
  {
    id: 'tipoRemolque',
    label: 'Trailer-type',
    xs: 12,
    required: true,
    multiple: true,
  },
  {
    id: 'caracteristicas',
    label: 'Characteristics',
    multiple: true,
    xs: 12,
  },
  {
    id: 'accesorios',
    label: 'Accessories',
    multiple: true,
    xs: 12,
  },
  {
    id: 'tipoDocumento',
    label: 'Fleet.Home.New-So.RequiredDocumentation',
    multiple: true,
    xs: 12,
  },
];

export const FormItemsOrigen = () => {
  const [t] = useTranslation();
  return [
    {
      id: 'retiroDateTimeRangeSelector',
      subtitle: t('Fleet.Home.New-So.Origin'),
    },
    {
      id: 'retiro',
      label: t('Fleet.Home.New-So.Address'),
      xs: 12,
      required: true,
      placeholder: t('Fleet.Home.New-So.Enter-the-origin-address'),
    },
  ];
};

export const FormItemsDestino = () => {
  const [t] = useTranslation();
  return [
    {
      id: 'entregaDateTimeRangeSelector',
      subtitle: t('Fleet.Home.New-So.Destination'),
    },
    {
      id: 'entrega',
      label: t('Fleet.Home.New-So.Address'),
      xs: 12,
      required: true,
      placeholder: t('Fleet.Home.New-So.Enter-the-destination-address'),
    },
  ];
};

export const FormItemsOSCarga = () => {
  const [t] = useTranslation();
  return [
    {
      id: 'OSTipoCarga',
      label: t('Fleet.Home.New-So.Load-Type'),
      xs: 12,
      required: true,
    },
    {
      id: 'OSDescripcionCarga',
      label: t('Fleet.Home.New-So.Description-of-the-load'),
      xs: 12,
    },
  ];
};

export const formItemsCliente = [
  {
    id: 'cliente',
    label: 'Customer',
    xs: 12,
  },
];

export const formItemsCarga = [
  {
    id: 'esPaletizada',
    label: 'Palletized-load',
    xs: 12,
  },
  {
    id: 'producto',
    label: 'Product-name',
    xs: 12,
    required: true,
  },
];

export const formItemsCargaPaletizada = [
  {
    id: 'tipoPallet',
    label: 'Pallet-Type',
    xs: 12,
    required: true,
  },
  {
    id: 'cantidadPallets',
    label: 'Number-of-Pallets',
    type: 'number',
    xs: 12,
    required: true,
  },
  {
    id: 'pesoPallet',
    label: 'Total-weight',
    type: 'number',
    xs: 12,
    endProp: 'kg',
    required: true,
  },
  {
    id: 'valorMercaderia',
    label: 'Merchandise-value',
    xs: 12,
    type: 'number',
  },
  {
    id: 'moneda',
    label: 'Currency',
    xs: 12,
    list: 'currency',
  },
];

export const formItemsCargaNoPaletizada = [
  {
    id: 'peso',
    label: 'Weight',
    type: 'number',
    xs: 12,
    endProp: 'kg',
    required: true,
  },
  {
    id: 'volumen',
    label: 'Volume',
    type: 'number',
    xs: 12,
    endProp: 'm³',
  },
  {
    id: 'tipoEmbalaje',
    label: 'Type-of-packaging',
    xs: 12,
  },
  {
    id: 'valorMercaderia',
    label: 'Merchandise-value',
    xs: 12,
    type: 'number',
  },
  {
    id: 'moneda',
    label: 'Currency',
    xs: 12,
    list: 'currency',
  },
];

export const formItemsCargaNoPaletizadaCalcularVolumen = [
  {
    id: 'largo',
    label: 'Long',
    type: 'number',
    xs: 12,
    endProp: 'cm',
  },
  {
    id: 'ancho',
    label: 'Width',
    type: 'number',
    xs: 12,
    endProp: 'cm',
  },
  {
    id: 'alto',
    label: 'High',
    type: 'number',
    xs: 12,
    endProp: 'cm',
  },
];

export const FormItemsCarga = () => {
  return [
    {
      id: 'producto',
      label: 'Product-name',
      xs: 12,
      required: true,
    },

    {
      id: 'sku',
      label: 'Sku',
      xs: 12,
    },
    {
      id: 'ncm',
      label: 'NCM',
      xs: 12,
    },

    // {
    //   id: 'cantidad',
    //   label: t('Fleet.Home.New-So.Quantity'),
    //   xs: 12,
    // },
  ];
};

export const FormItemsCargaPaletizada = () => {
  return [
    {
      id: 'cantidad',
      label: 'Amount1',
      type: 'number',
      xs: 12,
    },

    {
      id: 'tipoPallet',
      label: 'Pallet-Type',
      xs: 12,
      required: true,
    },

    {
      id: 'cantidadPallets',
      label: 'Number-of-Pallets',
      type: 'number',
      xs: 12,
      required: true,
    },
    {
      id: 'pesoPallet',
      label: 'Total-weight',
      type: 'number',
      xs: 12,
      endProp: 'kg',
      required: true,
    },
    {
      id: 'valorMercaderia',
      label: 'Merchandise-value',
      xs: 12,
      type: 'number',
    },
    {
      id: 'monedaId',
      label: 'Currency',
      xs: 12,
    },
  ];
};

export const FormItemsCargaRapidaPaletizada = () => {
  return [
    {
      id: 'monto',
      label: 'Amount1',
      type: 'number',
      xs: 12,
    },

    {
      id: 'tipoPallet',
      label: 'Pallet-Type',
      xs: 12,
      required: true,
    },

    {
      id: 'cantidadPallets',
      label: 'Number-of-Pallets',
      type: 'number',
      xs: 12,
      required: true,
    },
    {
      id: 'pesoPallet',
      label: 'Total-weight',
      type: 'number',
      xs: 12,
      endProp: 'kg',
      required: true,
    },
    {
      id: 'valorMercaderia',
      label: 'Merchandise-value',
      xs: 12,
      type: 'number',
    },
    {
      id: 'monedaId',
      label: 'Currency',
      xs: 12,
    },
  ];
};

export const FormItemsCargaNoPaletizada = () => {
  return [
    {
      id: 'cantidad',
      label: 'Amount1',
      type: 'number',
      xs: 12,
    },
    {
      id: 'peso',
      label: 'Weight',
      type: 'number',
      xs: 12,
      endProp: 'kg',
      required: true,
    },
    {
      id: 'volumen',
      label: 'Volume',
      type: 'number',
      xs: 12,
      endProp: 'm³',
    },
    {
      id: 'tipoEmbalaje',
      label: 'Type-of-packaging',
      xs: 12,
    },
    {
      id: 'valorMercaderia',
      label: 'Merchandise-value',
      xs: 12,
      type: 'number',
    },
    {
      id: 'monedaId',
      label: 'Currency',
      xs: 12,
    },
  ];
};

export const FormItemsCargaRapidaNoPaletizada = () => {
  return [
    {
      id: 'cantidad',
      label: 'Amount1',
      type: 'number',
      xs: 12,
    },
    {
      id: 'peso',
      label: 'Weight',
      type: 'number',
      xs: 12,
      endProp: 'kg',
      required: true,
    },
    {
      id: 'volumen',
      label: 'Volume',
      type: 'number',
      xs: 12,
      endProp: 'm³',
    },
    {
      id: 'tipoEmbalaje',
      label: 'Type-of-packaging',
      xs: 12,
    },
    {
      id: 'valorMercaderia',
      label: 'Merchandise-value',
      xs: 12,
      type: 'number',
    },
    {
      id: 'monedaId',
      label: 'Currency',
      xs: 12,
    },
  ];
};

export const FormItemsCargaNoPaletizadaCalcularVolumen = (t) => {
  return [
    {
      id: 'largo',
      label: t('Fleet.Home.New-So.Long'),
      type: 'number',
      xs: 12,
      endProp: 'cm',
    },
    {
      id: 'ancho',
      label: t('Fleet.Home.New-So.Width'),
      type: 'number',
      xs: 12,
      endProp: 'cm',
    },
    {
      id: 'alto',
      label: t('Fleet.Home.New-So.High'),
      type: 'number',
      xs: 12,
      endProp: 'cm',
    },
  ];
};

export const FormItemsCargaCheck = (t) => {
  return [
    {
      id: 'esPaletizada',
      label: t('Fleet.Home.New-So.Palletized-load'),
      xs: 12,
    },
    {
      id: 'esSelectProduct',
      label: t('Fleet.Home.New-So.Select-Product'),
      xs: 12,
    },
  ];
};

export const FormItemsCargaSelectProduct = () => {
  return [
    {
      id: 'selectProduct',
      label: 'Product-name',
      xs: 12,
      required: true,
    },
    {
      id: 'sku',
      label: 'Sku',
      xs: 12,
    },
    {
      id: 'ncm',
      label: 'NCM',
      xs: 12,
    },
    // {
    //   id: 'cantidad',
    //   label: t('Fleet.Home.New-So.Quantity'),
    //   xs: 12,
    // },
  ];
};

export const itemsFlotaPropia = (t) => {
  return [
    {
      id: 'flotaPropia_tarifa',
      label: t('Order.Order-form.Tariff'),
      type: 'number',
      xs: 12,
      md: 6,
      lg: 4,
      required: true,
      element: 'input',
    },
    {
      id: 'flotaPropia_moneda',
      label: t('Order.Order-form.Currency'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'currency',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-currency'),
    },
    {
      id: 'flotaPropia_pais',
      label: t('Order.Order-form.Payment-country'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'country',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-payment-country'),
    },
    {
      id: 'flotaPropia_condicionPago',
      label: t('Order.Order-form.Payment-condition'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'paymentConditions',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-payment-method'),
    },
    {
      id: 'observacionPago',
      label: t('Order.payment observation'),
      type: 'text',
      xs: 12,
      md: 6,
      lg: 4,
      required: false,
      element: 'input',
      placeHolder: t('Order.payment observation'),
    },
  ];
};

export const itemsPublicarFavoritos = (t) => {
  return [
    {
      id: 'asignarFavorito_tarifa',
      label: t('Order.Order-form.Tariff'),
      type: 'number',
      xs: 12,
      md: 6,
      lg: 4,
      required: true,
      element: 'input',
    },
    {
      id: 'asignarFavorito_moneda',
      label: t('Order.Order-form.Currency'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'currency',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-currency'),
    },
    {
      id: 'asignarFavorito_pais',
      label: t('Order.Order-form.Payment-country'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'country',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-payment-country'),
    },
    {
      id: 'asignarFavorito_condicionPago',
      label: t('Order.Order-form.Payment-condition'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'paymentConditions',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-payment-method'),
    },
    {
      id: 'observacionPago',
      label: t('Order.payment observation'),
      type: 'text',
      xs: 12,
      md: 6,
      lg: 4,
      required: false,
      element: 'input',
      placeHolder: t('Order.payment observation'),
    },
    {
      id: 'asignarFavorito_otro',
      label: t('Order.Order-form.Alternative payment'),
      type: 'text',
      xs: 12,
      md: 6,
      lg: 4,
      required: true,
      placeHolder: t('Order.Order-form.Enter-alternative-payment'),
      element: 'input',
    },
    {
      id: 'asignarFavorito',
      option: 'Seleccionar Favorito',
      label: t('Menu.Favorite'),
      placeholder: t('Order.Order-form.Select-Favorite'),
      xs: 8,
      required: true,
      element: 'AutocompleteMultiple',
    },
  ];
};

export const reasignar = (t) => {
  return [
    {
      id: 'asignarFavorito_tarifa',
      label: t('Order.Order-form.Tariff'),
      type: 'number',
      xs: 12,
      md: 6,
      lg: 4,
      required: true,
      element: 'input',
    },
    {
      id: 'asignarFavorito_moneda',
      label: t('Order.Order-form.Currency'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'currency',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-currency'),
    },
    {
      id: 'asignarFavorito_pais',
      label: t('Order.Order-form.Payment-country'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'country',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-payment-country'),
    },
    {
      id: 'honorario_tarifa',
      label: 'Honorarios',
      type: 'number',
      xs: 12,
      md: 6,
      lg: 4,
      required: true,
      element: 'input',
    },
    {
      id: 'idMonedaHonorarios',
      label: t('Order.Order-form.Currency'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'currency',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-currency'),
    },
    {
      id: 'asignarFavorito_condicionPago',
      label: t('Order.Order-form.Payment-condition'),
      xs: 12,
      md: 6,
      lg: 4,
      list: 'paymentConditions',
      required: true,
      element: 'Autocomplete',
      placeHolder: t('Order.Order-form.Select-payment-method'),
    },
    {
      id: 'observacionPago',
      label: t('Order.payment observation'),
      type: 'text',
      xs: 12,
      md: 6,
      lg: 4,
      required: false,
      element: 'input',
      placeHolder: t('Order.payment observation'),
    },
    {
      id: 'asignarFavorito_otro',
      label: t('Order.Order-form.Alternative payment'),
      type: 'text',
      xs: 12,
      md: 6,
      lg: 4,
      required: true,
      placeHolder: t('Order.Order-form.Enter-alternative-payment'),
      element: 'input',
    },
    {
      id: 'asignarFavorito',
      option: 'Seleccionar Favorito',
      label: t('Menu.Favorite'),
      placeholder: t('Order.Order-form.Select-Favorite'),
      xs: 8,
      required: true,
      element: 'AutocompleteMultiple',
    },
  ];
};

export const itemsOpcionesPago = [
  {
    id: 'cantidad',
    label: 'Amount',
    type: 'number',
    xs: 12,
    required: false,
  },
  {
    id: 'monedaId',
    label: 'Currency',
    xs: 12,
    list: 'currency',
    required: false,
  },
  {
    id: 'condicionPago',
    label: 'Condition-of-Payment',
    xs: 12,
    list: 'paymentConditions',
    multiple: true,
    required: false,
  },
];
