import { GoogleMap } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import GoogleMapsContext from '../contexts/MapContext';

const Map = (props) => {
  const containerStyle = {
    width: '100%',
    height: '550px',
  };

  const { google } = useContext(GoogleMapsContext);

  if (google === 'Error loading maps') return google;
  if (google === 'Loading Maps') return google;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props?.location}
      zoom={props.zoom}
      onLoad={props.onMapLoad}
      onClick={props.onClick}
    >
      {props.children}
    </GoogleMap>
  );
};

Map.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
  zoom: PropTypes.number,
  onMapLoad: PropTypes.func,
  onClick: PropTypes.func,
};

export default React.memo(Map);
