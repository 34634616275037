import React, { useState, useEffect } from 'react';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
  Checkbox,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import { styled, lighten } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FeatherIcon from 'feather-icons-react';
import { useFormik, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../hooks/useAuth';
import { getOrderDetail, reAsign } from '../../../../api/order';
import { getList } from '../../../../components/modals/utils/List';
import CustomTextField from '../../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../../components/forms/custom-elements/CustomFormLabel';
import { reasignar } from '../../../fleet/OS/mocks/FormMock';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled('div')(({ theme }) => ({
  top: '-8px',
  padding: '4px 0px',
  // color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

const ModalAssing = ({ id, open, setOpen, idCompanyLoad }) => {
  const auth = useAuth();
  const loggedInCompany = auth?.companyData;
  const theme = useTheme();

  const [listValues, setListValues] = useState({
    typesOfCurrency: [],
    currency: [],
    paymentConditions: [],
    favorites: [],
    country: [],
  });

  const [loading, setLoading] = useState(false);

  const generarListoption = () => {
    const options = [];
    for (let i = 0; i < listValues?.favorites?.length; i++) {
      options.push({
        id: listValues.favorites[i].id,
        nombre: listValues.favorites[i].contacto.nombre,
        idEmpresa: listValues.favorites[i].idOtraEmpresa,
        nombreGrupo: 'Todos',
      });
    }
    for (let i = 0; i < listValues?.groupFavorites?.length; i++) {
      const element = listValues?.groupFavorites[i];
      for (let j = 0; j < element.seguidores?.length; j++) {
        options.push({
          id: element.seguidores[j].id,
          idEmpresa: element.seguidores[j].idOtraEmpresa,
          nombre: element.seguidores[j].contacto.nombre,
          nombreGrupo: element.nombre,
          idGrupo: element.id,
        });
      }
    }
    for (let i = 0; i < listValues?.favorites?.length; i++) {
      if (listValues.favorites[i].isFavorito) {
        options.push({
          id: listValues.favorites[i].id,
          idEmpresa: listValues.favorites[i].idOtraEmpresa,
          nombre: listValues.favorites[i].contacto.nombre,
          nombreGrupo: 'Favoritos',
        });
      }
    }
    for (let i = 0; i < listValues?.favorites?.length; i++) {
      if (!listValues.favorites[i].isFavorito) {
        options.push({
          id: listValues.favorites[i].id,
          idEmpresa: listValues.favorites[i].idOtraEmpresa,
          nombre: listValues.favorites[i].contacto.nombre,
          nombreGrupo: 'Red de Contacto',
        });
      }
    }
    return options;
  };

  const allOptions = generarListoption();

  const [body, setBody] = useState(null);
  const [t, i18n] = useTranslation();

  const handleClose = () => {
    if (!loading) setOpen(false);
  };

  // cierra el modal
  const handleCloseOutside = (_, reason) => {
    if (reason === 'backdropClick') handleClose();
  };

  const initialValues = {
    typePriceCondition: 'asignarFavorito',
    asignarFavorito_precio: 'precioFijo',
    asignarFavorito: [],
    asignarFavorito_condicionPago: [],
    asignarFavorito_moneda: '',
    asignarFavorito_tarifa: '',
    idMonedaHonorarios: '',
  };

  const handleasing = async (data) => {
    try {
      setLoading(true);
      const resp = await reAsign(data);
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // formulario
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        ...body,
        idTransportistas: values.asignarFavorito.map((item) => item.idEmpresa),
        idsCondicionPago: values.asignarFavorito_condicionPago.map((item) => item.id),
        idMoneda:
          values.asignarFavorito_moneda.id !== ''
            ? values.asignarFavorito_moneda.id
            : body.asignarFavorito_moneda?.id,
        valor: values.asignarFavorito_tarifa !== '' ? values.asignarFavorito_tarifa : body.valor,
        honorarios: values.honorario_tarifa,
        idMonedaHonorarios: values.idMonedaHonorarios.id,
      };

      await handleasing(data);
    },
  });

  const selectGroup = (event, option) => {
    if (event.target.checked) {
      const selectedFavorite = [
        ...option.options,
        ...(formik.values.asignarFavorito ? formik.values.asignarFavorito : []),
      ];
      formik.setFieldValue('asignarFavorito', selectedFavorite);
    } else {
      const selectedFavorite = formik.values.asignarFavorito.filter((value) =>
        option.options.includes(value.id),
      );
      formik.setFieldValue('asignarFavorito', selectedFavorite);
    }
  };

  const selectOne = (event, child) => {
    const newValue = event.target.checked
      ? [...formik.values?.asignarFavorito, child]
      : formik.values?.asignarFavorito.filter((value) => value.id !== child.id);
    formik.setFieldValue('asignarFavorito', newValue);
  };

  const isButtonDisabled =
    (body?.tipoServicio !== 'INFORMATICO' &&
      formik.values.asignarFavorito_precio === 'precioFijo' &&
      (!formik.values?.asignarFavorito.length > 0 ||
        !formik.values?.asignarFavorito_condicionPago.length > 0 ||
        formik.values?.asignarFavorito_moneda === '' ||
        formik.values?.asignarFavorito_tarifa === '')) ||
    (body?.tipoServicio === 'INFORMATICO' &&
      (!formik.values?.asignarFavorito.length > 0 ||
        !formik.values?.asignarFavorito_condicionPago.length > 0 ||
        formik.values?.honorarios === '' ||
        formik.values?.idMonedaHonorarios === ''));

  const fetchListValues = async () => {
    try {
      const list = await getList(loggedInCompany.id);
      const filterFav = list.favorites.filter((fav) => fav.idOtraEmpresa !== idCompanyLoad);
      setListValues({ ...list, favorites: filterFav });
    } catch (error) {
      console.error('Error fetching list values:', error);
    }
  };

  useEffect(() => {
    fetchListValues();
  }, []);
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const detail = await getOrderDetail(id);
        setBody({
          nroInterno: detail.data.nroPedido,
          idViaje: detail.data.idViaje,
          idPedido: detail.data.id,
          descripcion: detail.data.descripcion,
          periodoDeCarga: detail.data.periodoDeCarga,
          periodoDeDescarga: detail.data.periodoDeDescarga,
          ubicacionInicial: detail.data.ubicacionDesde,
          ubicacionFinal: detail.data.ubicacionHasta,
          asignarFavorito_condicionPago: [],
          asignarFavorito_tarifa: detail.data.valor,
          valor: detail.data.valor,
          asignarFavorito_moneda: listValues.currency.find(
            (moneda) => moneda.simbolo === detail.data.moneda,
          ),
          tipoServicio: detail.data.tipoServicio,
        });
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [id, listValues]);

  const handleKeyPress = (event) => {
    const key = event.key.toLowerCase();
    if (key === '-' || key === 'e') {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      fullWidth
      onClose={!loading ? handleCloseOutside : null}
      maxWidth="lg"
      scroll="paper"
      aria-labelledby="modal-asignar-a-terceros"
      open={open}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            id="modal-asignar-a-terceros"
            width={1}
            p={2}
            pt={1}
            pb={1}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box display="flex" alignItems="center">
              <FeatherIcon icon="info" color={theme.palette.primary.main} />
              <Typography variant="h3" ml={1}>
                {t('Loads.Assign-to-third-parties')}
              </Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <FeatherIcon icon="x" />
            </IconButton>
          </Box>

          <DialogContent dividers sx={{ backgroundColor: alpha(theme.palette.primary.dark, 0.8) }}>
            <Paper elevation={10} sx={{ px: 4, py: 4 }}>
              <Grid container >
                <Grid container margin={0} >
                  <Grid xs={12}>
                    <FormControl>
                      <RadioGroup
                        row
                        value={
                          body?.tipoServicio === 'INFORMATICO'
                            ? 'precioFijo'
                            : formik.values.asignarFavorito_precio
                        }
                        onChange={(e) =>
                          formik.setFieldValue('asignarFavorito_precio', e.target.value)
                        }
                        disabled={body?.tipoServicio === 'INFORMATICO'}
                      >
                        <FormControlLabel
                          value="precioFijo"
                          control={<Radio />}
                          label={t('Order.Order-form.Fixed-price')}
                          disabled={body?.tipoServicio === 'INFORMATICO'}
                        />
                        <FormControlLabel
                          value="subasta"
                          control={<Radio />}
                          label={t('Order.Order-form.Auction')}
                          disabled={body?.tipoServicio === 'INFORMATICO'}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid  xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.concuerdaFlota}
                            onChange={(e) =>
                              formik.setFieldValue('concuerdaFlota', e.target.checked)
                            }
                          />
                        }
                        label={t('Order.Order-form.Notify')}
                      />
                    </FormControl>
                  </Grid>

                  {reasignar(t).map((item) => {
                    if (
                      (body?.tipoServicio !== 'INFORMATICO' && item.id === 'honorario_tarifa') ||
                      (body?.tipoServicio !== 'INFORMATICO' && item.id === 'idMonedaHonorarios')
                    ) {
                      return null;
                    }

                    if (item.element === 'input') {
                      if (
                        formik?.values?.asignarFavorito_condicionPago?.nombre !== 'Otro' &&
                        item.id === 'asignarFavorito_otro'
                      )
                        return <></>;
                      return (
                        <Grid  xs={12} md={6} lg={4} key={item.id} alignItems="center">
                          <Grid my="1rem">
                            <CustomFormLabel htmlFor={item.id}>
                              {item.label}{' '}
                              {item.required && formik.values.asignarFavorito_precio !== 'subasta'
                                ? '*'
                                : ''}
                            </CustomFormLabel>
                          </Grid>
                          <Grid>
                            <CustomTextField
                              id={item.id}
                              name={item.id}
                              placeholder={item.placeHolder}
                              onBlur={formik.handleBlur}
                              type={item.type}
                              value={
                                body?.tipoServicio === 'INFORMATICO'
                                  ? body[item.id]
                                  : formik.values[item.id]
                              }
                              onChange={formik.handleChange}
                              variant="outlined"
                              fullWidth
                              onKeyPress={(e) => item?.type === 'number' && handleKeyPress(e)}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              disabled={
                                (item.id === 'asignarFavorito_tarifa' ||
                                  item.id === 'asignarFavorito_moneda' ||
                                  item.id === 'asignarFavorito_pais' ||
                                  item.id === 'asignarFavorito_condicionPago' ||
                                  item.id === 'observacionPago') &&
                                (formik.values.asignarFavorito_precio === 'subasta' ||
                                  (body?.tipoServicio === 'INFORMATICO' &&
                                    item.id !== 'observacionPago'))
                              }
                            />
                          </Grid>
                        </Grid>
                      );
                    }

                    if (item.element === 'Autocomplete') {
                      return (
                        <Grid key={item.id} item xs={12} md={6} lg={4} alignItems="center">
                          <Autocomplete
                            id={item.id}
                            options={listValues?.[`${item.list}`]}
                            name={item.id}
                            multiple={item.id === 'asignarFavorito_condicionPago'}
                            value={
                              formik.values[item.id]
                                ? formik.values[item.id]
                                : item.id === 'asignarFavorito_condicionPago'
                                ? []
                                : body?.tipoServicio === 'INFORMATICO'
                                ? body[item.id]
                                : null
                            }
                            onChange={(_, newValue) => formik.setFieldValue(item.id, newValue)}
                            getOptionLabel={(option) => {
                              if (i18n.language === 'pt') return `${option?.nombrePortugues}`;
                              if (i18n.language === 'en') return `${option?.nombreIngles}`;
                              return `${option.nombre}`;
                            }}
                            disabled={
                              ((item.id === 'asignarFavorito_tarifa' ||
                                item.id === 'asignarFavorito_moneda' ||
                                item.id === 'asignarFavorito_pais' ||
                                item.id === 'asignarFavorito_condicionPago' ||
                                item.id === 'observacionPago') &&
                                (formik.values.asignarFavorito_precio === 'subasta' ||
                                  (body?.tipoServicio === 'INFORMATICO' &&
                                    item.id !== 'asignarFavorito_pais' &&
                                    item.id !== 'asignarFavorito_condicionPago' &&
                                    item.id !== 'observacionPago'))) ||
                              listValues?.favorites.length === 0
                            }
                            renderInput={(params) => (
                              <>
                                <Grid my="1rem">
                                  <CustomFormLabel>
                                    {item.label}{' '}
                                    {item.required &&
                                    formik.values.asignarFavorito_precio !== 'subasta'
                                      ? '*'
                                      : ''}
                                  </CustomFormLabel>
                                </Grid>
                                <CustomTextField
                                  {...params}
                                  placeholder={item.placeHolder}
                                  variant="outlined"
                                  fullWidth
                                  autoComplete="off"
                                />
                              </>
                            )}
                          />
                        </Grid>
                      );
                    }

                    if (item.element === 'AutocompleteMultiple') {
                      return (
                        <Grid  xs={12}>
                          <Autocomplete
                            options={allOptions}
                            fullWidth
                            multiple
                            disabled={allOptions.length === 0}
                            value={formik.values.asignarFavorito}
                            onChange={(event, newValue) => {
                              formik.setFieldValue('asignarFavorito', newValue);
                            }}
                            groupBy={(option) => option.nombreGrupo}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.nombre}
                            renderInput={(params) => (
                              <>
                                <CustomFormLabel>
                                  {item.label} {item.required ? '*' : ''}
                                </CustomFormLabel>
                                <CustomTextField
                                  {...params}
                                  error={Boolean(
                                    formik.touched.asignarFavorito && formik.errors.asignarFavorito,
                                  )}
                                  helperText={
                                    formik.touched.asignarFavorito && formik.errors.asignarFavorito
                                  }
                                  size="medium"
                                  placeholder={
                                    formik.values.asignarFavorito?.length === 0
                                      ? item.placeholder
                                      : null
                                  }
                                />
                              </>
                            )}
                            renderGroup={(params) => {
                              const extendedParams = {
                                ...params,
                                options: allOptions.filter(
                                  (option) => option.nombreGrupo === params.group,
                                ),
                              };
                              return (
                                <li key={extendedParams.key}>
                                  <GroupHeader>
                                    <Grid container>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={extendedParams.options.every((option) =>
                                          formik.values?.asignarFavorito?.some(
                                            (value) => value.id === option.id,
                                          ),
                                        )}
                                        onChange={(event) => {
                                          selectGroup(event, extendedParams);
                                        }}
                                      />{' '}
                                      <Typography variant="h3" sx={{ mt: 1 }}>
                                        {' '}
                                        {extendedParams.group}
                                      </Typography>
                                    </Grid>
                                  </GroupHeader>
                                  {(extendedParams.group === 'Todos' ||
                                    extendedParams.group === 'Favoritos' ||
                                    extendedParams.group === 'Red de Contacto') &&
                                    extendedParams.options &&
                                    extendedParams.options.map((child, index) => (
                                      <Box
                                        key={`${child.id}`}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          checked={formik.values?.asignarFavorito?.some(
                                            (value) => value.id === child.id,
                                          )}
                                          onChange={(event) => {
                                            selectOne(event, child);
                                          }}
                                        />
                                        {child.nombre}
                                      </Box>
                                    ))}
                                </li>
                              );
                            }}
                          />
                        </Grid>
                      );
                    }

                    return null;
                  })}
                </Grid>
                <Grid  xs={6} align="left">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ width: '180px' }}
                    onClick={handleClose}
                    disabled={loading}
                  >
                    <Typography variant="h3">{t('Button.Cancel')}</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6} align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ width: '180px' }}
                    type="submit"
                    // disabled={isButtonDisabled || loading}
                  >
                    {loading ? <CircularProgress />:<Typography variant="h3">{t('ActionListTable.Assign')}</Typography>}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
        </form>
      </FormikProvider>
    </Dialog>
  );
};
export default ModalAssing;
