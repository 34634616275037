import React from 'react'
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const  getFileIcon = (fileIconString) => {
    switch (fileIconString.split('.').pop()) {
      case 'jpeg':
      case 'jpg':
      case 'png':
        return <ImageIcon sx={{ color: (theme) => `${theme.palette.error.main} !important` }} />;
      case 'pdf':
        return (
          <PictureAsPdfIcon sx={{ color: (theme) => `${theme.palette.error.main} !important` }} />
        );
      case 'doc':
      case 'docx':
        return <TextSnippetIcon sx={{ color: (theme) => `${theme.palette.info.main} !important` }} />;
      case 'xls':
      case 'xlsx':
        return (
          <TableChartIcon sx={{ color: (theme) => `${theme.palette.success.main} !important` }} />
        );
      default:
        return (
          <InsertDriveFileIcon
            sx={{ color: (theme) => `${theme.palette.primary.main} !important` }}
          />
        );
    }
  }

export default getFileIcon