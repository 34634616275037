import axios from 'axios';
import { CUSTOMER_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const saveCustomer = async (customer) => {
  return axios.post(`${CUSTOMER_PATH}/guardar`, customer);
};


export const getCustomers = async (searchParam, idEmpresa) => {
  return axios.get(`${CUSTOMER_PATH}/listado${idEmpresa ? `?idEmpresa=${idEmpresa}` : ''}`, {
    params: {
      searchParam,
    },
  });
};

export const getCustomerByTaxIdentifier = async (tax) => {
  return axios.get(`${CUSTOMER_PATH}/buscar-identificador?identificador=${tax}`);
};

export const filterCustomers = async (search) => {
  const squery =
    search.trim().toUpperCase().length > 0
      ? `?search=${search.trim().toUpperCase()}`
      : "";

  return axios.get(CUSTOMER_PATH + squery);
};

export const deleteCustomers = async (id) => {
  return axios.delete(`${CUSTOMER_PATH}/eliminar`, { params: { id } });
};

export const getCustomer = async (id) => {
  return axios.get(`${CUSTOMER_PATH}/${id}`);
}

export const exportTemplateCustomers = async () => {
  return axios({
    url: `${CUSTOMER_PATH}/plantilla-xls`,
    method: 'GET',
    responseType: 'blob', 
  });
}

export const importCustomers = async (formData) => {
  return axios.post(`${CUSTOMER_PATH}/importarxls`, formData);
}

export const exportCustomers = async () => {
  return axios({
    url: `${CUSTOMER_PATH}/exportarXlsx`,
    method: 'GET',
    responseType: 'blob', 
  });
}