import axios from 'axios';
import { PACKAGING_TYPE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getPackagings = async () => {
  return axios.get(`${PACKAGING_TYPE_PATH}/listado`);
};

export const createPackaging = async (packaging) => {
  return axios.post(`${PACKAGING_TYPE_PATH}/guardar`, packaging);
};

export const getPackagingTypesPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${PACKAGING_TYPE_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};
export const getAllPackagingTypesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${PACKAGING_TYPE_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deletePackagingType = async (rolId) => {
  return axios.delete(`${PACKAGING_TYPE_PATH}/eliminar?id=${rolId}`);
};
