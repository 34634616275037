/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  Typography,
  useTheme,
  Paper,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  DirectionsCar,
  Domain,
  Event,
  Speed,
  MoveToInbox,
  ScaleSharp,
  Abc,
  Email,
  CalendarMonth,
  LocalPhone,
  FilterList,
} from '@mui/icons-material';
import { getDriver } from '../../../../../../api/driver';

const DriverDetailModal = ({ open, handleClose, vehicleID }) => {
  const theme = useTheme();
  const [vehicle, setVehicle] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const handleClick = (key) => {
    closeSnackbar(key);
  };

  const getDriverFunction = async () => {
    try {
      if (vehicleID) {
        const { data } = await getDriver(vehicleID);
        setVehicle(data);
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error?.response?.data?.error || t('Configuration.ServerError')}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 4000,
        onClick: (key) => handleClick(key),
      });
    }
  };

  useEffect(() => {
    if (open) {
      getDriverFunction();
    }
  }, [open]);

  const infoItems = [
    { label: t('ModalActivos.Nombre'), value: vehicle?.nombre, icon: <Abc /> },
    { label: t('ModalActivos.Apellido'), value: vehicle?.apellido || '-', icon: <Abc /> },
    { label: t('ModalActivos.Email'), value: vehicle?.email, icon: <Email /> },
    {
      label: t('ModalActivos.FechaNacimiento'),
      value: vehicle?.fechaNacimiento || '-',
      icon: <CalendarMonth />,
    },
    { label: t('ModalActivos.Telefono'), value: vehicle?.telefono, icon: <LocalPhone /> },
    { label: t('ModalActivos.Estado'), value: vehicle?.estado, icon: <FilterList /> },
    { label: t('ModalActivos.NombreEmpresa'), value: vehicle?.nombreEmpresa, icon: <Domain /> },
  ];

  if (vehicle) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="vehicle-info-dialog"
      >
        <Box
          id="vehicle-info-dialog"
          width={1}
          p={2}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h5" color={theme.palette.primary.main}>
            {t('ModalActivos.Informacion')}
          </Typography>
          <IconButton onClick={handleClose}>
            <Typography variant="h6">X</Typography>
          </IconButton>
        </Box>

        <DialogContent dividers>
          <Paper elevation={3} sx={{ px: 4, py: 4 }}>
            <Grid container spacing={2}>
              {infoItems.map((item, index) => (
                <Grid item xs={12} key={index} display="flex" alignItems="center">
                  {item.icon}
                  <Typography variant="subtitle1" ml={1} fontWeight="bold">
                    {item.label}:
                  </Typography>
                  <Typography variant="body1" ml={1}>
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" alignItems="center" py={4}>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {t('ModalActivos.NoInformacion')}{' '}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DriverDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DriverDetailModal;
