import {
  alpha,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import CustomFormLabel from '../../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../../components/forms/custom-elements/CustomTextField';
import { createCargo } from '../../../../api/cargoia';

const Details = ({ setOpenDetail, openDetail, load, setLoad, loads, setLoads }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      porcentaje: '',
      montoActual: '',
      montoACobrar: '',
    },
    onSubmit: async (values) => {
      const porcentaje = parseFloat( parseFloat((values.montoActual / values.montoACobrar) * 100 - 100).toFixed(2))
        const formData = new FormData();
    if(load.pathArchivos) {
      for (let i = 0; i < load.pathArchivos.length; i++) {
        formData.append('archivos', load.pathArchivos[i]);
      }
    }
      formData.append('id', load.id);
      formData.append('fecha', new Date(load.fecha));
      formData.append('idEmpresa', load.idEmpresa);
      formData.append('idCarga', load.idCarga);
      formData.append('mensajeTranscripto', load.mensajeTranscripto);
      formData.append('pathArchivos', load.pathArchivos);
      formData.append('probabilidadDeRetorno', load.probabilidadDeRetorno);
      formData.append('publicada', load.publicada);
      formData.append('porcentajeFollowGo', load.porcentajeFollowGo);
      formData.append('propEnviada', load.propEnviada);
      formData.append('aceptaCliente', load.aceptaCliente);
      formData.append('montoActual', values.montoActual);
      formData.append('montoACobrar', values.montoACobrar);
      formData.append('porcentaje', porcentaje);
        try {
          const { data } = await createCargo(formData);
          setLoad(data)
          setLoads(loads.map((l) => (l.id === data.id ? data : l)));
        } catch(error) {
          console.log(error)
        }

    },
  });
  const texto = 'El margen de utilidad es inferior al mínimo requerido (4%) la carga pasara a estado de verificación antes de publicarse.'
  return (
    <Dialog
      fullWidth
      onClose={() => setOpenDetail(false)}
      open={openDetail}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle>{t('Load Details')}</DialogTitle>
      <DialogContent dividers sx={{ backgroundColor: alpha(theme.palette.primary.dark, 0.8) }}>
        <Paper elevation={10} sx={{ px: 4, py: 4 }}>
          <Grid container>
              <Grid  xs={12} key="porcentaje" sx={{ mb: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h2" sx={{textAlign: 'center'}}>{texto}</Typography>
              <Typography variant="h3" sx={{textAlign: 'center'}}>El lider de equipo debe confirmar el margen de utilidad</Typography>
                    <Grid  xs={12} sx={{ mt: 5 }} justifyContent="center">
                      <Typography variant="h2">{t('Porcentaje')} {load?.porcentajeUtilidad}%</Typography>
                    <Button
                              disabled={formik.isSubmitting}
                              variant="contained"
                              type="submit"
                              onClick={formik.handleSubmit}
                            >
                              {t('Configuration.Confirm')}
                            </Button>
                    </Grid>
                    </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default Details;
