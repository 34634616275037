import React, { useEffect, useState } from 'react';
import { Grid, Pagination, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CargaCard } from './CargaCard';
import { useCargas } from './cargaHook';
import HeaderBarWithSort from './HeaderBarWithSort';
import DetalleDeLaPublicacion from './DetalleDeCarga/DetalleDeLaPublicacion';
import { getCargasPaginado } from '../../../../api/marketplace';

const Cargas = ({ selectedFilters, url, permitirOrdenar, comparator, setComparator }) => {
  const [t] = useTranslation();
  const {
    // cargas,
    detalleAbierto,
    detalleCarga,
    editCarga,
    handleDetalle,
    removeCarga,
    reloadCargas,
  } = useCargas(selectedFilters, url);
  const [cargas, setCargas] = useState([]);
  const [order, setOrder] = useState('DESC');
  const [page, setPage] = useState(0);
  const sizePage = 10;
  const [modificacionDeOrden, setModificacionDeOrden] = useState(false);

  const getCargas = async () => {
    const listadoCargas = await getCargasPaginado(
      page,
      sizePage,
      'nroCarga',
      selectedFilters,
      order,
    );
    setCargas(listadoCargas.data);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    getCargas();
  };

  const ordenar = () => {
    setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    getCargas();
  };

  useEffect(() => {
    getCargas();
  }, [selectedFilters]);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const cargaCompartida = cargas?.find((carga) => carga.id === id);
      if (cargaCompartida) handleDetalle(cargaCompartida);
    }

    console.log('cargas:', cargas);
  }, [cargas]);

  return (
    <>
      <HeaderBarWithSort
        cantidadCargas={cargas?.size}
        ordenar={ordenar}
        setCargas={setCargas}
        setModificacionDeOrden={setModificacionDeOrden}
        modificacionDeOrden={modificacionDeOrden}
        permitirOrdenar={permitirOrdenar}
      />
      <Box sx={{ overflowY: 'auto' }}>
        {cargas?.content?.length > 0 ? (
          <>
            {cargas.content.map((unaPublicacion) => {
              return (
                <CargaCard
                  key={unaPublicacion.id}
                  publicacion={unaPublicacion}
                  removeCarga={removeCarga}
                  editCarga={editCarga}
                  open={detalleAbierto}
                  handleDetalle={handleDetalle}
                  comparator={comparator}
                  setComparator={setComparator}
                />
              );
            })}
            {detalleCarga && detalleAbierto && (
              <DetalleDeLaPublicacion
                open={detalleAbierto}
                handleClose={handleDetalle}
                detalle={detalleCarga}
                reloadDetails={reloadCargas}
              />
            )}
            <Pagination
              count={cargas.totalPages}
              page={page + 1}
              onChange={handleChangePage}
              sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5, mt: 5 }}
              color="primary"
            />
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, width: '100%' }}>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
              elevation={3}
            >
              <Typography color="#616161" variant="h3">
                {`${t('Message.No results found')}`}
              </Typography>
            </Paper>
          </Box>
        )}
      </Box>
      <Box sx={{ height: '70vh', overflowY: 'auto', display: 'contents' }}>
        <Grid
          item
          xs={12}
          md={6}
          align="center"
          display="content"
          alignItems="center"
          justifyContent="center"
          mt={4}
          ml={50}
          mr={50}
        >
          {/* {!checkFav && (
                <Button
                  size="small"
                  variant="contained"
                   onClick={add}
                   sx={{
                    width:'100%',
                  }}
                >
                  <Typography variant="h3">Agregar a favoritos</Typography>
                </Button>
                )} */}
        </Grid>
      </Box>
    </>
  );
};

export default Cargas;
