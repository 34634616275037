import { getCountries } from "../../../api/country";
import { getCurrencies } from "../../../api/currency";
import { getOnlyActiveFavorites } from "../../../api/favorite";
import { getFavoritesByCompanyId } from "../../../api/favoritesGroups";
import { getPaymentConditions } from "../../../api/paymentConditions";

export const getList = async (idCompany) => {
    const [
        currencyResponse,
        paymentConditionsResponse,
        favoritesResponse,
        groupFavorites,
        countryResponse,
      ] = await Promise.allSettled([
        getCurrencies(),
        getPaymentConditions(),
        getOnlyActiveFavorites(),
        getFavoritesByCompanyId(idCompany),
        getCountries(),
      ]);
    
      return {
        typesOfCurrency: currencyResponse.value?.data || [],
        currency: currencyResponse.value?.data || [],
        paymentConditions: paymentConditionsResponse.value?.data || [],
        favorites: favoritesResponse.value || [],
        country: countryResponse.value?.data || [],
        groupFavorites: groupFavorites.value.data || []
      };
}