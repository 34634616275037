const validation = (value, publicacion, t) => {
  if (!value.valor || value.valor === 0) {
    return { success: false, error: t('The value must be greater than 0') };
  }

  if (!value.idMoneda) {
    return { success: false, error: t('Travel-expenses.The currency is required') };
  }

  if (!value.idVehiculo) {
    return { success: false, error: t('Validation.vehiculoRequerido') };
  }

  if (!value.idChofer) {
    return { success: false, error: t('Validation.choferRequerido') };
  }

  if (!value.idRemolque && publicacion.tipoRemolque) {
    return { success: false, error: t('Validation.RemolqueRequerido') };
  }

  return { success: true, error: null };
};

export default validation;
