import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import ReactPixel from 'react-facebook-pixel';
import OrderView from '../marketplace/dashboard/OrderView';
import { getPublicOrderDetail } from '../../api/marketplace';
import { listarContraofertasRecibidas } from '../../api/contraoferta';
import useAuth from '../../hooks/useAuth';

const pixelId = '3968532016746212';

const PublicOrderView = () => {
  const { authenticatedData, isAuthenticated } = useAuth();

  const [infoSelected, setInfoSelected] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [noOrderError, setNoOrderError] = useState(false);

  const { id: orderId } = useParams();

  async function getOffersReceived(orderIdToSearch) {
    try {
      const { data: offersFound } = await listarContraofertasRecibidas(orderIdToSearch);
      return offersFound;
    } catch (error) {
      return [];
    }
  }

  function getOfferStatus(offers) {
    const { idEmpresa } = authenticatedData;

    let oferto = 0;

    for (let i = 0; i < offers?.length || 0; i++) {
      if (offers[i].estado === 'ACEPTADO')
        return offers[i].creador.idEmpresa === idEmpresa
          ? 'OFERTA ACEPTADA'
          : 'OFERTA RECHAZADA - CERRADA';
      if (offers[i].creador.idEmpresa === idEmpresa) {
        if (offers[i].estado === 'PENDIENTE') return 'OFERTA PENDIENTE';
        oferto++;
      }
    }

    return oferto > 0 ? 'OFERTA RECHAZADA - ABIERTA' : 'ABIERTA';
  }

  async function loadOrder() {
    try {
      const { data: orderFound } = await getPublicOrderDetail(orderId);
      setInfoSelected(orderFound);

      if (orderFound.id && isAuthenticated) {
        if (orderFound.recibirOfertas || orderFound.estadoPedidoNombre === 'Ofertado') {
          const historialDeOfertas = await getOffersReceived(orderFound.id);
          orderFound.historialOfertas = historialDeOfertas;
          orderFound.estatus = getOfferStatus(historialDeOfertas);
        }
        setInfoSelected(orderFound);
      } else setNoOrderError('El pedido no existe');
    } catch (e) {
      console.log(e);
      setServerError('Error del servidor');
    }
  }

  useEffect(() => {
    const advancedMatching = {  }; // Opcional, para una mejor coincidencia
    const options = {
      autoConfig: true, // establece la configuración automática del píxel
      debug: false, // habilita los registros
    };
    ReactPixel.init(pixelId, advancedMatching, options);

    ReactPixel.pageView(); // Para rastrear las vistas de página
    if (orderId && orderId.length > 0) {
      loadOrder();
    }
  }, []);

  return (
      <OrderView
        infoSelectd={infoSelected}
        serverError={serverError}
        setServerError={setServerError}
        noOrderError={noOrderError}
        reloadOrder={loadOrder}
      >
        {' '}
      </OrderView>
  );
};

export default PublicOrderView;
