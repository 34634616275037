import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popper, Typography, Box, Divider } from '@mui/material';

const PopperCount = ({count}) =>  {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      };
  
    const open = Boolean(anchorEl);
  
    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
        
          onClick={handleClick}
        >
          {count?.length}
        </Typography>
        <Popper
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          disablePortal={false}
        >
          <Box sx={{ border: 1, borderColor: 'primary.main', p: 2, bgcolor: 'background.paper' }}>
          <Typography variant="h4"> Empresas:</Typography>
          <Divider sx={{borderColor: '#ffbf3c', my: '1rem'}} />
            {count && count.map((empresa) => <Typography variant="body2">
              {empresa.nombre}
            </Typography>)}
          </Box>
        </Popper>
      </div>
    );
  }

export default PopperCount