import React, { useContext, useEffect, useState } from 'react'
import { Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PageContainer from '../../../components/container/PageContainer'
import Breadcrumb from '../../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import LateralModal from '../../../components/LateralModal';
import LoadAITable from './components/LoadAITable';
import { getAllCargo, getCargoOrder } from '../../../api/cargoia';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';
import { mtTypographyTableButtons, sxTableButtons } from '../../../constants/styleTableButton';
import LoadAIForm from './components/LoadAIForm';
import LoadForm from './components/LoadForm';
import { getListSimple } from '../../fleet/OS/utils/List';
import Details from './components/Details';
import ModalAssing from './components/ModalAssing';
import { getLastOrder } from '../../../api/order';
import { getCount } from '../../../api/offer';

const LoadAI = () => {
  const [showForm, setShowForm] = useState(false)
const { t } = useTranslation();
    const BCrumb = [
        {
          to: '/',
          title: 'Home',
        },
        {
          title: t('Load AI'),
        },
      ];


  const { widthScreen, mxTableButtons, myTableButtons, typographyVariantButton } =
  useContext(ScreenSizeContext);
    const theme = useTheme();
    const [loads, setLoads] = useState([])
    const [loading, setLoading] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [listValues, setListValues] = useState({})
    const [companies, setCompanies] = useState([])
    const [load, setLoad] = useState(null)
    const [loadIA, setLoadIA] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [showAssing, setShowAssing] = useState(false)

    const getLoads = async () => {
      setLoading(true)
      const { data } = await getAllCargo();
      if(data && data.length > 0) {
         /* eslint-disable no-await-in-loop */
        for(let i = 0; i < data.length; i++) {
          if(data[i].idCarga) {
            try {
              const {data:selected} = await getCount(data[i].idCarga);
              data[i] = {contador:selected, ...data[i]}
            } catch (error) {
              console.log(error)
            }
          }
        }
         /* eslint-disable no-await-in-loop */
      }
      setLoads(data);
      setLoading(false)
    }

    const handleViewForm = async(loadSelected) => {
      setOpenDetail(true)
      if(loadSelected.idCarga) {
      const {data:selected} = await getCargoOrder(loadSelected.idCarga);
      setLoad(selected)
    } else {
      const { data:selected} = await getLastOrder(loadSelected.idEmpresa)
      setLoad(selected)
    }
    setLoadIA(loadSelected)
    }

    const viewModal = (loadSelect) => {
      setShowDetails(true)
      setLoad(loadSelect)
    }

    const margenBajoModal = (newLoad) => {
      setShowDetails(true)
      setLoad(newLoad)
    }
    
    const viewModalAssing = (loadSelect) => {
      setShowAssing(true)
      setLoad(loadSelect)
    }

    const getList = async () => {
      const list = await getListSimple();
      setListValues(list)
      setCompanies(list.favorites?.map((fav) => {
          return {
            id: fav.idOtraEmpresa,
            label: fav.contacto?.nombre || fav.contacto?.razonSocial,
          };
        }))
    }

    useEffect(() => {
      getLoads()
      getList()
    }, [])
    

  return (
    <PageContainer title="Load AI">
        <Breadcrumb items={BCrumb} />
      <Paper sx={{ my: { xs: 0.5, sm: 0.5, md: 1, lg: 1.5, xl: 2 } }}>
        <Grid container p={{ sx: 0, sm: 0.5, md: 1 }}>
          <Grid
            container
            xs={widthScreen > 450 ? 5 : 12}
            sm={5}
            md={6}
            sx={{
              justifyContent: widthScreen > 450 ? 'start' : 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ ...sxTableButtons, mx: mxTableButtons, my: myTableButtons, mt: '0.5rem' }}
              startIcon={<AddBoxIcon style={{ fontSize: '24px' }} />}
              onClick={() => setShowForm(true)}
            >
              <Typography
                variant={typographyVariantButton}
                sx={{ ml: widthScreen > 420 ? 0 : -0.5, mt: mtTypographyTableButtons }}
              >
                {t('Button.Add')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <LoadAITable
        loads={loads}
        setLoads={setLoads}
        loading={loading}
        setOpenForm={handleViewForm}
        setShowDetails={viewModal}
        viewModalAssing={viewModalAssing}
        
      />
      {showForm &&
        <LoadAIForm
        setShowForm={setShowForm}
        showForm={showForm}
        favorites={companies}
        listValues={listValues}
        loads={loads}
        setLoads={setLoads}
        />
      }
      {showDetails && 
      <Details setOpenDetail={setShowDetails} openDetail={showDetails} load={load} setLoad={setLoad}
      loads={loads} 
      setLoads={setLoads}
      />}
      {
        showAssing && 
        <ModalAssing 
        open={showAssing} setOpen={setShowAssing} id={load?.idCarga} idCompanyLoad={load?.idEmpresa}/>
      }
      {openDetail && (
        <LateralModal
          open={openDetail}
          handleClose={() => setOpenDetail(false)}
          title='Nueva carga:'
          sx={{
            '& ~ .pac-container': {
              zIndex: theme.zIndex.modal + 1,
            },
          }}
        >
            <Grid item xs={12} spacing={2} p={2} >
              <LoadForm  listValues={listValues} load={load} loadIA={loadIA} loads={loads} 
      setLoads={setLoads} setOpenDetail={setOpenDetail} setShowDetails={margenBajoModal}/>
              </Grid>
            
        </LateralModal>
      )}
    </PageContainer>
  )
}

export default LoadAI