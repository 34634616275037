import axios from 'axios';
import { PRODUCT_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getProducts = async (idEmpresa) => {
    return axios.get(`${PRODUCT_PATH}/listado${idEmpresa ? `?idEmpresa=${idEmpresa}` : ''}`);
  };

export const getProduct = async (id) => {
    return axios.get(`${PRODUCT_PATH}/buscar?id=${id}`);
};

export const createProduct = async (formData) => {
  return axios.post(`${PRODUCT_PATH}/guardar`, formData);
};

export const editProduct = async (editedProduct) => {
  return axios.post(`${PRODUCT_PATH}/guardar`,editedProduct);
};

export const deleteProduct = async (id) => {
  return axios.delete(`${PRODUCT_PATH}/eliminar?id=${id}`);
};

export const importProducts = async (formData) => {
  return axios.post(`${PRODUCT_PATH}/importarexcel`, formData);
};

export const exportTemplateProducts = async () => {
  return axios({
    url: `${PRODUCT_PATH}/plantillaxlsx`,
    method: 'GET',
    responseType: 'blob', 
  });
};