import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';

const CustomFormLabel = styled((props) => {
  const { heightScreen } = useContext(ScreenSizeContext);
  return <Typography variant="h6" {...props} component="label" htmlFor={props.htmlFor} />;
})((props) => ({
  marginBottom: props.heightScreen < 415 ? '1px' : '5px',
  marginTop: props.heightScreen < 415 ? '5px' : '15px',
  display: 'block',
}));

export default CustomFormLabel;
