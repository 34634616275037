import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LateralModal from '../../../../../components/LateralModal';
import ActionButton from '../components/ActionButton';
import DetalleMap from '../components/DetalleMap';
import ShowAlert from '../../../components/ShowAlert';
import ResponderUna from './ResponderUna';
import Chat from './Chat';
import LabelWithIcon from '../../../../../components/LabelWithIcon';
import LoadCard from '../../../../../components/LoadCard';
import ModalLoadDetails from '../../../../../components/modals/ModalLoadDetails';

import { useActionHook } from './actionsHook';
import useAuth from '../../../../../hooks/useAuth';

import { getTranslation } from '../../../../../utils/translation';

const DetalleDeLaPublicacion = ({
  open,
  handleClose,
  detalle,
  reloadDetails,
  hideActions = false,
  showEdit = false,
}) => {
  const [t, i18] = useTranslation();
  const theme = useTheme();

  const { aceptar, aceptar2, contactar, preguntar, contraoferta, compartir } = useActionHook(
    detalle,
    reloadDetails,
  );

  const [options, setOptions] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ title: '', msg: '' });

  const [modalAction, setModalAction] = useState(false);
  const [aQuestion, setAquestion] = useState();

  const [choosenLoad, setChoosenLoad] = useState(null);
  const [openModalViewLoads, setOpenModalViewLoads] = useState(false);

  const { authenticatedData } = useAuth();

  const chipStyle = { fontSize: '12px', letterSpacing: '1px', color: theme.palette.text.secondary };

  function showAlertModal(alertTitle, alertMsg) {
    setAlert({
      title: alertTitle,
      msg: alertMsg,
    });
    setShowAlert(true);
  }

  function sortAnswers(x, y) {
    const firstDate = new Date(x.alta);
    const secondDate = new Date(y.alta);

    if (firstDate < secondDate) return -1;
    if (firstDate > secondDate) return 1;
    return 0;
  }

  const handleCloseModal = () => {
    setModalAction(false);
  };

  function handleViewLoadDetails(loadToView) {
    setChoosenLoad(loadToView);
    setOpenModalViewLoads(true);
  }

  useEffect(() => {
    if (detalle) {
      let optionsToAdd = [contactar, preguntar, compartir];
      if (!detalle?.recibirOfertas)
        if (detalle?.estadoCarga?.texto === 'Ofrecida')
          optionsToAdd = [
            { ...aceptar2, action: () => aceptar2.action(null, showAlertModal) },
            ...optionsToAdd,
          ];
        else
          optionsToAdd = [
            { ...aceptar, action: () => aceptar.action(null, showAlertModal) },
            ...optionsToAdd,
          ];
      else optionsToAdd = [contraoferta, ...optionsToAdd];
      setOptions(optionsToAdd);
    }
  }, [detalle]);

  const subtitulefechaInicio = () => {
    if (detalle?.periodoDeCarga?.fechaInicio) {
      const fechaObjeto = new Date(detalle?.periodoDeCarga?.fechaInicio);
      const fechaFormateada =
        i18.language === 'en'
          ? fechaObjeto.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : fechaObjeto.toLocaleDateString('es-ES', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
      const horaFormateada = fechaObjeto.toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${fechaFormateada} ${horaFormateada} hs`;
    }
    const horaInicio = new Date(detalle?.periodoDeCarga?.horaInicio);
    const horaFin = new Date(detalle?.periodoDeCarga?.horaFinalizacion);
    const fechaInicioFormateada =
      i18.language === 'en'
        ? new Date(detalle?.periodoDeCarga?.inicio).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : new Date(detalle?.periodoDeCarga?.inicio).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
    const fechaFinFormateada =
      i18.language === 'en'
        ? new Date(detalle?.periodoDeCarga?.finalizacion).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : new Date(detalle?.periodoDeCarga?.finalizacion).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
    const horaInicioFormateada = horaInicio.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const horaFinFormateada = horaFin.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${fechaInicioFormateada} ${horaInicioFormateada} hs - ${fechaFinFormateada} ${horaFinFormateada} hs`;
  };

  const subtitulefechaFin = () => {
    if (detalle?.periodoDeDescarga?.fechaInicio) {
      const fechaObjeto = new Date(detalle?.periodoDeDescarga?.fechaInicio);
      const fechaFormateada =
        i18.language === 'en'
          ? fechaObjeto.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : fechaObjeto.toLocaleDateString('es-ES', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
      const horaFormateada = fechaObjeto.toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${fechaFormateada} ${horaFormateada} hs`;
    }
    const horaInicio = new Date(detalle?.periodoDeDescarga?.horaInicio);
    const horaFin = new Date(detalle?.periodoDeDescarga?.horaFinalizacion);
    const fechaInicioFormateada =
      i18.language === 'en'
        ? new Date(detalle?.periodoDeDescarga?.inicio).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : new Date(detalle?.periodoDeDescarga?.inicio).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });

    const fechaFinFormateada =
      i18.language === 'en'
        ? Date(detalle?.periodoDeDescarga?.finalizacion).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : new Date(detalle?.periodoDeDescarga?.finalizacion).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });

    const horaInicioFormateada = horaInicio.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const horaFinFormateada = horaFin.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${fechaInicioFormateada} ${horaInicioFormateada} hs - ${fechaFinFormateada} ${horaFinFormateada} hs`;
  };

  return (
    <>
      <LateralModal open={open} handleClose={handleClose} title={t('Order.TitleDetails')}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {detalle?.nroCarga && (
                <Grid item xs={12} mb={2}>
                  <Divider textAlign="center">
                    <Chip label={t('Order.Order-info')} sx={chipStyle} />
                  </Divider>
                </Grid>
              )}
              {detalle?.pedidos && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.Order-number')}
                    subTitle={detalle?.nroCarga}
                    featherIcon="hash"
                  />
                  <LabelWithIcon
                    title={t('TableHeader.Status')}
                    subTitle={t(`Status.${detalle?.estadoCargaNombre}`)}
                    featherIcon="help-circle"
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              {detalle?.empresaCreadora && (
                <Grid item xs={12} mb={1}>
                  <Divider textAlign="center">
                    <Chip label={detalle?.empresaCreadora} sx={chipStyle} />
                  </Divider>
                </Grid>
              )}
              {detalle?.descripcion && detalle?.descripcion.length > 0 && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.LoadDescription')}
                    subTitle={detalle?.descripcion}
                    featherIcon="message-circle"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <LabelWithIcon
                  title={t('Order.TravelType')}
                  subTitle={detalle?.tipoDeViajeNombre || '-'}
                  featherIcon="send"
                />
              </Grid>
              {detalle?.tipoCamion && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.VehicleType')}
                    subTitle={getTranslation(i18, detalle?.tipoCamion)}
                    featherIcon="truck"
                  />
                </Grid>
              )}
              {detalle?.tipoRemolque && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.TrailerType')}
                    subTitle={getTranslation(i18, detalle?.tipoRemolque)}
                    featherIcon="truck"
                  />
                </Grid>
              )}
              {detalle?.accesorios && detalle?.accesorios.length > 0 && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.Accessories')}
                    subTitle={detalle?.accesorios
                      .reduce((acum, a) => `${acum}, ${t(`Loads.Accessories.${a}`)}`, '')
                      .slice(1)}
                    featherIcon="paperclip"
                  />
                </Grid>
              )}
              {detalle?.ubicacionDesde && detalle?.periodoDeCarga && (
                <Grid item xs={12} mb={1}>
                  <Divider textAlign="center">
                    <Chip label={t('Order.Origin')} sx={chipStyle} />
                  </Divider>
                </Grid>
              )}
              {detalle?.ubicacionDesde && detalle?.ubicacionDesde?.direccion && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.Origin')}
                    subTitle={detalle?.ubicacionDesde?.direccion}
                    featherIcon="map-pin"
                  />
                </Grid>
              )}
              {detalle?.periodoDeCarga && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.LoadingPeriod')}
                    //   subTitle={`${detalle?.periodoDeCarga.inicio} (${detalle?.periodoDeCarga.horaInicio} hs) -
                    // ${detalle?.periodoDeCarga.finalizacion} (${detalle?.periodoDeCarga.horaFinalizacion} hs)`}
                    subTitle={subtitulefechaInicio()}
                    featherIcon="clock"
                  />
                </Grid>
              )}
              {detalle?.ubicacionHasta && detalle?.periodoDeDescarga && (
                <Grid item xs={12} mb={1}>
                  <Divider textAlign="center">
                    <Chip label={t('Order.Destination')} sx={chipStyle} />
                  </Divider>
                </Grid>
              )}
              {detalle?.ubicacionHasta && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.Destination')}
                    subTitle={detalle?.ubicacionHasta?.direccion}
                    featherIcon="map-pin"
                  />
                </Grid>
              )}
              {detalle?.periodoDeDescarga && (
                <Grid item xs={12}>
                  <LabelWithIcon
                    title={t('Order.UnloadingPeriod')}
                    //   subTitle={`${detalle?.periodoDeDescarga.inicio} (${detalle?.periodoDeDescarga.horaInicio} hs) -
                    // ${detalle?.periodoDeDescarga.finalizacion} (${detalle?.periodoDeDescarga.horaFinalizacion} hs)`}
                    subTitle={subtitulefechaFin()}
                    featherIcon="clock"
                  />
                </Grid>
              )}
              <Grid item xs={12} mb={1}>
                <Divider textAlign="center">
                  <Chip label={t('Order.OrderLoads')} sx={chipStyle} />
                </Divider>
              </Grid>
              {detalle?.pedidos.map((carga, i) => {
                return (
                  <Grid item xs={6} display="flex" key={`carga-${carga.id}-${i.toString()}`}>
                    <LoadCard item={carga} handleDetails={() => handleViewLoadDetails(carga)} />
                  </Grid>
                );
              })}

              <Grid item xs={12} mt={2} mb={3}>
                <Divider textAlign="center">
                  <Chip label={t('Order.Route')} sx={chipStyle} />
                </Divider>
              </Grid>

              <DetalleMap detalle={detalle} fullWidth />

              <Grid item xs={12} mb={1}>
                <Divider>
                  <Chip label={t('Order.Conditions')} sx={chipStyle} />
                </Divider>
              </Grid>
              {!detalle?.recibirOfertas ? (
                <>
                  <Grid item xs={12}>
                    <LabelWithIcon
                      title={t('Order.PaymentCondition')}
                      subTitle={detalle?.condicionPago}
                      featherIcon="credit-card"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LabelWithIcon
                      title={t('Order.Value')}
                      subTitle={`${detalle?.valor ? detalle?.valor : ''} ${
                        detalle?.moneda ? detalle?.moneda : ''
                      }`}
                      featherIcon="dollar-sign"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <LabelWithIcon title={t('Order.SentToAuction')} featherIcon="refresh-ccw" />
                </Grid>
              )}

              {!hideActions && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <ActionButton options={options} handleClose={handleClose} />
                </Grid>
              )}

              {detalle?.preguntas && detalle?.preguntas.length > 0 && (
                <>
                  <Grid item xs={12} mb={1}>
                    <Divider>
                      <Chip label={t('Order.Questions')} sx={chipStyle} />
                    </Divider>
                  </Grid>

                  <Grid item xs={12}>
                    {detalle?.preguntas.sort(sortAnswers).map((unaPregunta, index) => {
                      return (
                        <Chat
                          key={`chat-${unaPregunta.id}-${index.toString()}`}
                          unaPregunta={unaPregunta}
                          setModalAction={setModalAction}
                          setAquestion={setAquestion}
                          authenticatedData={authenticatedData}
                          sortFunction={sortAnswers}
                        />
                      );
                    })}
                  </Grid>
                </>
              )}

              {showEdit && (
                <Grid item xs={12}>
                  <Button
                    size="small"
                    variant="contained"
                    component={Link}
                    disabled={
                      detalle?.estadoPedido !== 'PUBLICADA' &&
                      detalle?.estadoPedido !== 'PUBLICADA_FAVORITOS' &&
                      detalle?.estadoPedido !== 'ASIGNADA'
                    }
                    to={`/carga/order/${detalle?.id}`}
                    sx={{
                      mt: 2,
                      width: '200px',
                      float: 'right',
                    }}
                  >
                    <Typography variant="h3">{t('Button.Edit')}</Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </LateralModal>

      <ShowAlert
        open={showAlert}
        handleClose={() => setShowAlert(false)}
        msg={alert.msg}
        title={alert.title}
      />

      <Dialog open={modalAction} onClose={handleCloseModal}>
        <ResponderUna
          question={aQuestion}
          onClose={handleCloseModal}
          order={detalle}
          loadOrder={reloadDetails}
          authenticatedData={authenticatedData}
        />
      </Dialog>

      <ModalLoadDetails
        open={openModalViewLoads}
        handleClose={() => setOpenModalViewLoads(false)}
        load={choosenLoad}
      />
    </>
  );
};

export default DetalleDeLaPublicacion;
