import axios from 'axios';
import { TRAILER_TYPE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getTrailerTypes = async () => {
  return axios.get(`${TRAILER_TYPE_PATH}/listado`);
};

export const createTrailerType = async (trailerType) => {
  return axios.post(`${TRAILER_TYPE_PATH}/guardar`, trailerType);
};

export const getTrailerImg = (id) => {
  return `${TRAILER_TYPE_PATH}/ver-img/${id}`;
};

export const getAllTrailerpesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${TRAILER_TYPE_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getTrailerTypesListPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${TRAILER_TYPE_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deleteTrailerTypes = async (id) => {
  return axios.delete(`${TRAILER_TYPE_PATH}/eliminar?id=${id}`);
};
