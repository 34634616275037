import axios from 'axios';
import { TRUCK_TYPE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getTruckTypes = async () => {
  return axios.get(`${TRUCK_TYPE_PATH}/listar`);
};

export const getTruckImg = (id) => {
  return `${TRUCK_TYPE_PATH}/ver-img/${id}`;
};

export const getAllTruckTypesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${TRUCK_TYPE_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getTruckTypesListPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${TRUCK_TYPE_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deleteTruckTypes = async (id) => {
  return axios.delete(`${TRUCK_TYPE_PATH}/eliminar?id=${id}`);
};

export const createTruckType = async (truck) => {
  return axios.post(`${TRUCK_TYPE_PATH}/guardar`, truck);
};
