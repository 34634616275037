import axios from 'axios';
import { VEHICLE_TYPE_PATH, VEHICLE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getVehicleTypes = async () => {
  return axios.get(`${VEHICLE_PATH}/tipoVehiculo/listado`);
};

export const createVehicleType = async (vehicleType) => {
  return axios.post(`${VEHICLE_TYPE_PATH}/guardar`, vehicleType);
};
