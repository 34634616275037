import React from 'react';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useTranslation } from 'react-i18next';
import TableCard from '../../../../components/table-card/TableCard';
import { createCargo } from '../../../../api/cargoia';
import PopperCount from './PopperCount';

const LoadAITable = ({
  loads,
  setLoads,
  loading,
  setOpenForm,
  setShowDetails,
  viewModalAssing,
}) => {
  const { t } = useTranslation();

  const sendProposal = async (load) => {
    setLoads(
      loads.map((l) => (l.id === load.id ? { ...load, propEnviada: !load.propEnviada } : l)),
    );
    const formData = new FormData();
    if (load.pathArchivos) {
      for (let i = 0; i < load.pathArchivos.length; i++) {
        formData.append('archivos', load.pathArchivos[i]);
      }
    }
    formData.append('id', load.id);
    formData.append('fecha', new Date(load.fecha));
    formData.append('idEmpresa', load.idEmpresa);
    formData.append('nombreEmpresa', load.nombreEmpresa);
    formData.append('idCarga', load.idCarga);
    formData.append('idContacto', load.idContacto)
    formData.append('idConversacion', load.idConversacion);
    formData.append('origen', load.origen);
    formData.append('destino', load.destino);
    formData.append('estado', load.estado);
    formData.append('aceptaCliente', load.aceptaCliente);
    formData.append('montoActual', load.montoActual);
    formData.append('montoACobrar', load.montoACobrar);
    formData.append('mensajeTranscripto', load.mensajeTranscripto);
    formData.append('pathArchivos', load.pathArchivos);
    formData.append('probabilidadDeRetorno', load.probabilidadDeRetorno);
    formData.append('publicada', load.publicada);
    formData.append('porcentajeFollowGo', load.porcentajeFollowGo);
    formData.append('propEnviada', !load.propEnviada);

    await createCargo(formData);
  };

  const handleCheckClient = async (load) => {
    setLoads(
      loads.map((l) => (l.id === load.id ? { ...load, aceptaCliente: !load.aceptaCliente } : l)),
    );
    const formData = new FormData();
    if (load.pathArchivos) {
      for (let i = 0; i < load.pathArchivos.length; i++) {
        formData.append('archivos', load.pathArchivos[i]);
      }
    }
    formData.append('id', load.id);
    formData.append('fecha', new Date(load.fecha));
    formData.append('idEmpresa', load.idEmpresa);
    formData.append('nombreEmpresa', load.nombreEmpresa);
    formData.append('idCarga', load.idCarga);
    formData.append('idContacto', load.idContacto)
    formData.append('idConversacion', load.idConversacion);
    formData.append('origen', load.origen);
    formData.append('destino', load.destino);
    formData.append('estado', load.estado);
    formData.append('montoActual', load.montoActual);
    formData.append('montoACobrar', load.montoACobrar);
    formData.append('mensajeTranscripto', load.mensajeTranscripto);
    formData.append('pathArchivos', load.pathArchivos);
    formData.append('probabilidadDeRetorno', load.probabilidadDeRetorno);
    formData.append('publicada', load.publicada);
    formData.append('porcentajeFollowGo', load.porcentajeFollowGo);
    formData.append('aceptaCliente', !load.aceptaCliente);
    // setShowDetails(load);
    await createCargo(formData);
  };

  return (
    <TableCard title={t('Loads.Table.CargaIA')} custompadding="0px" customMargin="0px">
      <Box sx={{ overflow: 'auto' }}>
        {loading ? (
          <LinearProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('TableHeader.Status')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('TableHeader.indicePrecio')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('TableHeader.fechaRecepcion')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('TableHeader.Customer')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('ActionListTable.Create-order')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('TableHeader.Origin')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('TableHeader.Destination')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Precio neto dador')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Precio neto transportista')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Utilidad')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>Confirmación de carga</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>Confirma cliente</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('TableHeader.publicada')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Cantidad de envíos')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Cantidad recepción')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Cantidad rechazadas')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Cantidad contraofertas')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Cantidad reservas')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Reservada')}</Typography></TableCell>
                  <TableCell><Typography sx={{transform: 'rotate(-45deg)'}}>{t('Aprobada por cliente')}</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loads || loads.length === 0 ? (
                  <></>
                ) : (
                  loads.map((load) => (
                    <TableRow key={`${load.id}-key`}>
                      <TableCell>{load.nombreEstado}</TableCell>
                      <TableCell>---</TableCell>
                      <TableCell>{format(new Date(load.fecha), 'dd/MM/yyyy HH:mm')}</TableCell>
                      <TableCell>{load.nombreEmpresa || '---'}</TableCell>
                      <TableCell onClick={() => setOpenForm(load)}>
                        <Button variant="outlined" sx={{padding: '5px'}}>
                          {load.idCarga ? 'Detalle' : 'Crear Carga'}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {load.origen || '---'} 
                      </TableCell>
                      <TableCell>{load.destino || '---'}</TableCell>
                      <TableCell>{load.montoActual || '---'}</TableCell>
                      <TableCell>{load.montoACobrar || '---'}</TableCell>
                      <TableCell>{
                      load.montoActual && load.montoACobrar ?`${parseFloat( parseFloat((load.montoActual / load.montoACobrar) * 100 - 100).toFixed(2))}%` : '---'}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => sendProposal(load)}>
                          {load.propEnviada ? (
                            <CheckBoxOutlinedIcon color="primary" />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleCheckClient(load)}>
                          {load.aceptaCliente ? (
                            <CheckBoxOutlinedIcon color="primary" />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell onClick={() => !load.publicada && viewModalAssing(load)}>
                        <Box display="flex" alignItems="center">
                          {!load.publicada ? (
                            <Button variant="outlined"  sx={{padding: '5px'}} disabled={!load.idCarga}>Publicar</Button>
                          ) : (
                            <CheckBoxOutlinedIcon color="primary" />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell><PopperCount count={load?.contador?.Enviadas} /></TableCell>
                      <TableCell><PopperCount count={load?.contador?.Recibidas} /></TableCell>
                      <TableCell><PopperCount count={load?.contador?.Rechazadas} /></TableCell>
                      <TableCell><PopperCount count={load?.contador?.Contraofertas} /></TableCell>
                      <TableCell><PopperCount /></TableCell>
                      <TableCell>Transporte Calderon</TableCell>
                      <TableCell>
                        <CheckBoxOutlinedIcon color="primary" />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </TableCard>
  );
};

export default LoadAITable;
