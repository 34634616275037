import axios from 'axios';
import { OFFER } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getOffersReceived = async ( size, page, sortType, direction ) => {
    return axios.get(`${OFFER}/listado-recibidas-page?size=${size}&page=${page}&sort=${sortType},${direction.toUpperCase()}`);
  };

  export const rejectOffersReceived = async ( idPedido ) => {
    return axios.get(`${OFFER}/rechazar?idPedido=${idPedido}`);
  };

  export const getCount = async (idCarga) => { 
    return axios.get(`${OFFER}/contador-interacciones?idCarga=${idCarga}`)
  }