import axios from 'axios';
import { TEXT_TEMPLATE_PATH } from '../config';

export const createTemplate = async (model) => {
  return axios.post(`${TEXT_TEMPLATE_PATH}/guardar`, model);
};

export const getTemplatesList = async (id) => {
  return axios.get(`${TEXT_TEMPLATE_PATH}/listarPorEmpresa${id ? `?id=${id}` : ''}`);
};

export const deleteTemplate = async (id) => {
  return axios.delete(`${TEXT_TEMPLATE_PATH}/eliminar?id=${id}`);
};

export const changeTemplateStatus = async (id) => {
  return axios.get(`${TEXT_TEMPLATE_PATH}/cambiar-estado?idPlantilla=${id}`);
};
