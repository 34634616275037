import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DialogContainer from '../../../../../components/modals/DialogContainer';
import IntroCard from './IntroCard';
import CoverCard from './CoverCard';
import { Documento } from '../../../../fleet/documents/components/Logos';
import ModalDrawerDocument from './ModalDrawerDocument';
import { CheckPermissions } from '../../../../../utils/relatedToPermissions';
import useAuth from '../../../../../hooks/useAuth';
import { ViewAttachment } from '../../../../../api/documents';
import { getCompany } from '../../../../../api/company';
import VehicleDetailModal from './util/VehicleDetailModal';
import TrailerDetailModal from './util/TrailerDetailModal';
import DriverDetailModal from './util/DriverDetailModal';

const ShowOffers = ({
  offers,
  handleResignOffer,
  handleAcceptOffer,
  authenticatedData,
  idEmpresaCreadora,
}) => {
  const auth = useAuth();
  const p = auth?.allPermissions;
  const [t] = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [choosenDocument, setChoosenDocument] = useState(null);
  const [company, setCompany] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState('');
  const [openVehicleModal, setOpenVehicleModal] = useState(false);
  const [selectedTrailerId, setSelectedTrailerID] = useState('');
  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState('');
  const [openDriverModal, setOpenDriverModal] = useState(false);

  function sortOffers(oneOffer, otherOffer) {
    return new Date(otherOffer.alta) - new Date(oneOffer.alta);
  }

  useEffect(() => {}, []);

  const loadCompany = async (id) => {
    try {
      const { data } = await getCompany(id);
      setCompany(data);
      setOpenModal(true);
    } catch (error) {
      console.log('error');
    }
  };

  function handleOptions(option, document, imgIndex = 0) {
    switch (option) {
      case t('Button.Open'):
        window.open(ViewAttachment(document.archivos[imgIndex]), '_blank');
        break;
      default:
    }
  }

  function handleOpenVehicleDetailModal(vehicleId) {
    setSelectedVehicleId(vehicleId);
    setOpenVehicleModal(true);
  }

  function handleOpenTrailerDetailModal(trailerID) {
    setSelectedTrailerID(trailerID);
    setOpenTrailerModal(true);
  }

  function handleOpenDriverDetailModal(driverID) {
    setSelectedDriverId(driverID);
    setOpenDriverModal(true);
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <TableContainer sx={{ maxHeight: '400px' }}>
          <Table
            sx={{
              whiteSpace: {
                xs: 'nowrap',
                sm: 'unset',
              },
              width: '100%',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.ofertante')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.fecha')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.valor')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.incluye_iva')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.comentario')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.estado')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.chofer')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.vehiculo')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.remolque')}</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="h4">{t('Marketplace.acciones')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers?.sort(sortOffers).map((offer, index) => {
                const key = `oferta-${offer.id}-${index}`;
                if (
                  authenticatedData?.idEmpresa === offer.creador.idEmpresa ||
                  authenticatedData?.idEmpresa === idEmpresaCreadora
                ) {
                  return (
                    <TableRow key={key}>
                      <TableCell>
                        <Chip
                          label={<Typography variant="h3">{offer?.empresaNombre}</Typography>}
                          onClick={() => loadCompany(offer?.creador.idEmpresa)}
                          color="warning"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {offer.alta ? moment(offer.alta).format('DD/MM/YYYY HH:mm') : ''}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {offer?.valor} {offer?.moneda?.simbolo}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {offer?.incluyeIva ? t('Si') : t('No')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{offer?.comentarios || '---'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {t(`Status.${offer?.estadoNombre}`)}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Box
                          component="div"
                          onClick={() => handleOpenDriverDetailModal(offer?.chofer?.id)}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                          }}
                        >
                          <Typography variant="body1">
                            {offer?.chofer
                              ? `${offer?.chofer?.nombre} ${offer?.chofer?.apellido}`
                              : '-'}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          component="div"
                          onClick={() => handleOpenVehicleDetailModal(offer?.vehiculo?.id)}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                          }}
                        >
                          <Typography variant="body1">
                            {offer?.vehiculo
                              ? `${offer?.vehiculo?.marca?.nombre} ${offer?.vehiculo?.modelo}`
                              : '-'}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          component="div"
                          onClick={() => handleOpenTrailerDetailModal(offer?.remolque?.id)}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                          }}
                        >
                          <Typography variant="body1">
                            {offer?.remolque
                              ? `${offer?.remolque?.tipoRemolque?.nombre} ${offer?.remolque?.dominio}`
                              : '-'}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell>
                        {offer && offer.estado === 'PENDIENTE' ? (
                          authenticatedData?.idEmpresa === idEmpresaCreadora && (
                            <Box display="flex" alignItems="center">
                              <IconButton
                                onClick={() => handleResignOffer(offer)}
                                aria-label="Rechazar oferta"
                                sx={{ marginRight: 1 }}
                                color="error"
                              >
                                <CloseIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleAcceptOffer(offer)}
                                aria-label="Aceptar oferta"
                                color="success"
                              >
                                <CheckIcon />
                              </IconButton>
                            </Box>
                          )
                        ) : offer && offer.estado === 'RECHAZADO' ? (
                          <Typography
                            variant="h5"
                            color="white"
                            textAlign="center"
                            sx={{ width: 1 }}
                          >
                            - {t('Status.OfertaRechazada')} -
                          </Typography>
                        ) : (
                          <Typography
                            variant="h5"
                            color="white"
                            textAlign="center"
                            sx={{ width: 1 }}
                          >
                            - {t('Status.OfertaAceptada')} -
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <DialogContainer
        open={openModal}
        title="Informacion de empresa"
        handleClose={() => setOpenModal(false)}
        maxWidth="lg"
      >
        <CoverCard company={company} />
        <IntroCard company={company} />
        <Grid>
          {company?.documentosCompartidos && (
            <>
              <Card>
                <CardContent>
                  <Typography variant="h3">{t('Menu.Documents')}</Typography>
                  {company?.documentosCompartidos?.map((doc) => {
                    return (
                      <Grid sx={{ mt: 2, width: '100px' }} key={doc.id}>
                        <IconButton
                          onClick={() => {
                            setChoosenDocument(doc);
                            setOpenDrawer(true);
                          }}
                        >
                          <Documento
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          />
                        </IconButton>
                        <Typography
                          align="center"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            wordWrap: 'break-word',
                          }}
                        >
                          {doc?.titulo}
                        </Typography>
                      </Grid>
                    );
                  })}
                </CardContent>
              </Card>
            </>
          )}
        </Grid>
      </DialogContainer>

      <ModalDrawerDocument
        viewModal={openDrawer}
        setViewModal={setOpenDrawer}
        document={choosenDocument}
        options={
          choosenDocument &&
          choosenDocument.archivos?.length > 0 &&
          CheckPermissions(auth, [p?.ROOT, p?.CRE_EMP, p?.EDI_DOC])
            ? [t('Button.Open')]
            : [t('Button.Open'), t('Button.Edit'), t('Button.Delete')]
        }
        handleOptions={handleOptions}
      />

      <VehicleDetailModal
        open={openVehicleModal}
        handleClose={() => setOpenVehicleModal(false)}
        vehicleID={selectedVehicleId}
      />

      <TrailerDetailModal
        open={openTrailerModal}
        handleClose={() => setOpenTrailerModal(false)}
        vehicleID={selectedTrailerId}
      />

      <DriverDetailModal
        open={openDriverModal}
        handleClose={() => setOpenDriverModal(false)}
        vehicleID={selectedDriverId}
      />
    </Grid>
  );
};

export default ShowOffers;
