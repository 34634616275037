import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import CustomTextField from '../../../../../components/forms/custom-elements/CustomTextField';
import { guardarContraoferta } from '../../../../../api/contraoferta';
import validation from './util/validation';
import { useSnackbarUtils } from '../../../../../hooks/useSnackbarUtils';

const ContraOferta = ({ publicacion, vehicles, drivers, trailers, setLoading, monedas }) => {
  const [t] = useTranslation();
  // const [monedas] = useFetch(getCurrencies);
  const { showAlert } = useSnackbarUtils();
  const getMoneda = () => {
    const moneda = monedas.find((m) => m.simbolo === publicacion.moneda);
    return moneda ? moneda.id : monedas[0]?.id;
  };

  const formik = useFormik({
    initialValues: {
      valor: publicacion.valor || '',
      idMoneda: getMoneda(),
      idVehiculo: '',
      idChofer: '',
      idRemolque: '',
      incluyeIva: false,
      isContraoferta: publicacion.valor && publicacion.valor !== 0,
      comentario: '',
      requerido: publicacion.pedirActivo,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const { success, error } = validation(values, publicacion, t);
        console.clear();
        console.log({ success, error });

        if (success) {
          await guardarContraoferta({ ...values, idCarga: publicacion.id });
          showAlert(t('Marketplace.Actions.OnSuccessCounteroffer'), 'success');
          window.location.reload();
        } else {
          showAlert(error, 'error');
        }
      } catch (error) {
        showAlert(error?.response?.data?.error || t('Errors.Server'), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form id="Oferta" onSubmit={formik.handleSubmit}>
      <FormControlLabel
          control={
            <Switch
              aria-label="Realizar contraoferta"
              id="isContraoferta"
              size="small"
              checked={!formik.values.isContraoferta}
              onChange={() => formik.setFieldValue('isContraoferta', !formik.values.isContraoferta)}
            />
          }
          label='Realizar contraoferta'
          labelPlacement="start"
          sx={{ mt: 2, mb: 2, ml: 0 }}
        />
        <CustomTextField
          id="valor"
          label={`${t('Fleet.Home.New-So.Amount')}*`}
          value={formik.values.valor}
          onBlur={formik.handleBlur}
          type="number"
          onChange={(e) => formik.setFieldValue('valor', e.target.value)}
          variant="outlined"
          fullWidth
          disabled={formik.values.isContraoferta}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />

        <FormControl id="formMoneda" fullWidth sx={{ mt: 2 }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {`${t('Products.Currency')}*`}
          </Typography>
          <Select
            value={formik.values.idMoneda}
            defaultValue={formik.values.idMoneda}
            disabled={formik.values.isContraoferta}
            onChange={(e) => formik.setFieldValue('idMoneda', e.target.value)}
            inputProps={{
              name: 'Moneda',
              id: 'Moneda',
            }}
          >
            {monedas &&
              monedas.map((moneda) => (
                <MenuItem key={moneda.id} value={moneda.id}>
                  {moneda.simbolo}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {publicacion.honorarios && (
          <Typography variant="h3" sx={{ mb: 1 }}>
          {`Honorarios: ${publicacion.honorarios} ${publicacion.monedaHonorarios}`}
        </Typography>
        )}

        <FormControl sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('AssetCard.Vehicle.DialogTitle')} *
          </Typography>
          <Autocomplete
            disablePortal
            options={vehicles}
            onChange={(event, newValue) => formik.setFieldValue('idVehiculo', newValue.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              formik?.values?.idVehiculo
                ? vehicles.find((option) => option.id === formik?.values?.idVehiculo)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} label={t('AssetCard.Vehicle.DialogTitle')} />
            )}
          />
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('AssetCard.Driver.DialogTitle')} *
          </Typography>
          <Autocomplete
            disablePortal
            options={drivers}
            onChange={(event, newValue) => formik.setFieldValue('idChofer', newValue.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              formik?.values?.idChofer
                ? drivers.find((option) => option.id === formik?.values?.idChofer)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} label={t('AssetCard.Driver.DialogTitle')} />
            )}
          />
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('AssetCard.Trailer.DialogTitle')} {publicacion.tipoRemolque ? '*' : ''}
          </Typography>
          <Autocomplete
            disablePortal
            options={trailers}
            onChange={(event, newValue) => formik.setFieldValue('idRemolque', newValue.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              formik?.values?.idRemolque
                ? trailers.find((option) => option.id === formik?.values?.idRemolque)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} label={t('AssetCard.Trailer.DialogTitle')} />
            )}
          />
        </FormControl>
        <TextField
          id="comentario"
          label={`${t('Types.Comentario')}`}
          multiline
          maxRows={4}
          fullWidth
          sx={{ mt: 2 }}
          onChange={(e) => formik.setFieldValue('comentarios', e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              aria-label="Incluye Iva"
              id="switchIva"
              size="small"
              checked={formik.values.incluyeIva}
              onChange={() => formik.setFieldValue('incluyeIva', !formik.values.incluyeIva)}
              disabled={publicacion.valor && publicacion.valor !== 0}
            />
          }
          label={`${t('Marketplace.Actions.Includes IVA')}`}
          labelPlacement="start"
          sx={{ mt: 2, ml: 0 }}
        />
      </form>
    </FormikProvider>
  );
};

export default ContraOferta;
