import { saveCargo } from "../../../../api/cargo";
import { publishOrder } from "../../../../api/order";


export const createCargoBack = async (carga) => {
    console.log("carga.tipoEmbalaje.id",carga.tipoEmbalaje.id)
    const pedido = {
        idTipoCarga: carga.tipoCarga,
        kmTotales: carga.kmTotales,
        periodoDeEntrega: {
            fechaInicio: carga.fechaDeCarga,
            fechaFin: carga.fechaDescarga,
        },
        origen: carga.ubicacionDesde,
        destino: carga.ubicacionHasta,
        itemPedido: [
            {
                peso: carga.pesoTotal,
                ispallet: carga.cantidadPallet > 0,
                producto: carga.producto,
                tipoEmbalajeId: carga.tipoEmbalaje,
            }
        ],
        monto: carga.valor,
        idMoneda: carga.moneda,
        idEmpresa: carga.empresaCreadora,
    }

    const {data:nuevoPedido} = await saveCargo(pedido);
    const newLoad = {
        tipoSservicio: carga.tipoServicio,
        pedidos: [nuevoPedido.id],
        tipoCamionId: carga.tiposCamion,
        tipoRemolqueId: carga.tiposRemolque,
        ubicacionDesde: carga.ubicacionDesde,
        ubicacionHasta: carga.ubicacionHasta,
        idEmpresaDadora: carga.empresaCreadora,
        periodoDeCarga: {
            fechaInicio: carga.fechaDeCarga,
            inicio:carga.fechaDeCarga,
            horaInicio:carga.fechaDeCarga,
            horaFinalizacion:carga.fechaDeCarga,
            fechaFin: carga.fechaDescarga,
            finalizacion:carga.fechaDescarga
        },
        periodoDeDescarga: {
            fechaInicio: carga.fechaDeCarga,
            inicio:carga.fechaDeCarga,
            horaInicio:carga.fechaDeCarga,
            horaFinalizacion:carga.fechaDeCarga,
            fechaFin: carga.fechaDescarga,
            finalizacion:carga.fechaDescarga
        },
        kmTotales: carga.kmTotales,
        valor: carga.valor,
        monedaId: carga.moneda,
        idsCondicionesPago: carga.condicionesDePago,
        observacionPago: carga.observacionPago,
        paisPagoId: carga.paisPago,
    }

    const {data:nuevaCarga} = await publishOrder({order: newLoad, favorito: true, idFavorite:[]});

  return nuevaCarga
}
