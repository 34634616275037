import axios from 'axios';
import { FAVORITE_GROUPS_PATH } from '../config';

export const getFavoritesByCompanyId = async (idEmpresa) => {
  return axios.get(`${FAVORITE_GROUPS_PATH}/buscar-por-empresa?idEmpresa=${idEmpresa}`);
};

export const getFavoritesById = async (id) => {
  return axios.get(`${FAVORITE_GROUPS_PATH}/buscar-por-id?id=${id}`);
};

export const deleteFavoritesGroups = async (id) => {
  return axios.delete(`${FAVORITE_GROUPS_PATH}/eliminar?id=${id}`);
};

export const saveGroup = async (model) => {
    return axios.post(`${FAVORITE_GROUPS_PATH}/guardar`, model);
};