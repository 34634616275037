import { useSnackbar } from 'notistack';

export const useSnackbarUtils = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClick = (key) => {
    closeSnackbar(key);
  };

  const showAlert = (msg, alertType) => {
    enqueueSnackbar(msg, {
      variant: alertType,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
      onClick: (key) => handleClick(key),
    });
  };

  return {
    showAlert,
  };
};