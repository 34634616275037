import axios from 'axios';
import { TAX_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getTaxes = async () => {
  return axios.get(`${TAX_PATH}/listado`);
};

export const getTaxesForIdCountry = async (id) => {
  return axios.get(`${TAX_PATH}/listado-por-pais?idPais=${id}`);
};

export const createTax = async (currency) => {
  return axios.post(`${TAX_PATH}/guardar`, currency);
};


export const deleteTax = async (id) => {
  return axios.delete(`${TAX_PATH}/eliminar?id=${id}`);
};
