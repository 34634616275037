import { useSelector } from 'react-redux';
import { modules } from '../constants/permissions';


export const ModuleRoutesWithPermissions = (auth, module) =>{
  
  const p = auth?.allPermissions;
  
  const load = [
    { route: 'inicio', permission: [p?.ROOT, p?.CRE_EMP, p?.LIST_PED, p?.CRE_PED] },
    { route: 'tablero', permission: [] },
    { route: 'kanban', permission: [] },
    { route: 'mapa', permission: [p?.VER_CAR_MAPA] },
    { route: 'planificacion', permission: [] },
    {
      route: 'cargas',
      permission: [p?.LIST_PED, p?.CRE_PED, p?.EDI_PED, p?.VER_PED, p?.ELI_PED, p?.COM_PED],
    },
    {
      route: 'pedidos',
      permission: [p?.LIST_CAR,p?.ACE_CAR,p?.ASIG_CAR,p?.CANC_CAR,p?.CRE_CAR,p?.EDI_CAR,p?.ELIM_CAR,],},
      { route: 'armado-de-cargas', permission: [] },
      {
        route: 'documentacion',
        permission: [p?.LIST_DOC, p?.CRE_DOC, p?.EDI_DOC, p?.VER_DOC, p?.ELI_DOC],
      },
      { route: 'clientes', permission: [p?.LIST_CLI, p?.CRE_CLI, p?.EDI_CLI, p?.VER_CLI, p?.ELI_CLI] },
      { route: 'favoritos', permission: [p?.LIST_FAV, p?.CRE_FAV, p?.EDI_FAV, p?.ELI_FAV, p?.VER_FAV] },
      { route: 'checklist/carga', permission: [p?.LIST_CHKLIST, p?.VER_CHKLIST, p?.CRE_CHKLIST, p?.EDI_CHKLIST, p?.ELI_CHKLIST] },
      { route: 'producto', permission: [p?.ROOT, p?.CRE_EMP,p?.CRE_PRODU,p?.LIST_PRODU,p?.VER_PRODU,p?.EDI_PRODU,p?.ELI_PRODU] },
      { route: 'panelControl', permission: [p?.VER_TORRE] },
    ];
    
    const fleet = [
      { route: 'inicio', permission: [p?.ROOT, p?.CRE_EMP, p?.LIST_CAR] },
      { route: 'os', permission: [] },
      { route: 'viajes', permission: [p?.LIST_VIA, p?.CRE_VIA, p?.EDI_VIA, p?.VER_VIA, p?.ELI_VIA] },
      { route: 'mapa', permission: [p?.VER_CAR_MAPA] },
      {
        route: 'activos',
        permission: [p?.LIST_VEH,p?.CRE_VEH,p?.EDI_VEH,p?.VER_VEH,p?.ELI_VEH,p?.LIST_CHF,p?.CRE_CHF,p?.EDI_CHF,p?.VER_CHF,p?.ELI_CHF,p?.LIST_REM,p?.CRE_REM,p?.EDI_REM,p?.VER_REM,p?.ELI_REM,p?.LIST_ACT,p?.CRE_ACT,p?.EDI_ACT,p?.VER_ACT,p?.ELI_ACT,],},
        { route: 'organizar', permission: [] },
        { route: 'orden-mantenimiento', permission: [p?.LIST_OM, p?.CRE_OM, p?.EDI_OM, p?.VER_OM, p?.ELI_OM] },
        { route: 'administrarActivos', permission: [] },
        { route: 'planificacion', permission: [] },
        {
          route: 'documentacion',
          permission: [p?.LIST_DOC, p?.CRE_DOC, p?.EDI_DOC, p?.VER_DOC, p?.ELI_DOC],
        },
        { route: 'clientes', permission: [p?.LIST_CLI, p?.CRE_CLI, p?.EDI_CLI, p?.VER_CLI, p?.ELI_CLI] },
        { route: 'favoritos', permission: [p?.LIST_FAV, p?.CRE_FAV, p?.EDI_FAV, p?.ELI_FAV, p?.VER_FAV] },
        { route: 'checklist/flota', permission: [p?.LIST_CHKLIST, p?.VER_CHKLIST, p?.CRE_CHKLIST, p?.EDI_CHKLIST, p?.ELI_CHKLIST] },
        { route: 'panelControl', permission: [p?.VER_TORRE] },
      ]; 
      
      const marketplace= [
        {route:"inicio",
        permission: [p?.ROOT,p?.CRE_EMP,p?.LIST_CAR,p?.VER_CAR_MAPA]},
      ]
      
      const workflow = [
        { route: 'inicio', permission: [p?.ROOT, p?.CRE_EMP, p?.LIST_CAR, p?.VER_CAR_MAPA]},
      ];
      
      
      return module === modules.load? load: module === modules.fleet? fleet : module === modules.marketplace? marketplace: null;
    }
    
    // crea un array con solo los permisos de cada modulo
    export const PermissonByModule = (auth, module)=>{
      const moduleArray = new Set();
      ModuleRoutesWithPermissions(auth, module).forEach(e=>{
        e.permission.forEach(p=>{
          moduleArray.add(p);
        })
      })
      
      const groupedPermissons = Array.from(moduleArray);

      return groupedPermissons;
    }

// retorna  true o false para activar o desactivar los botones de los modulos del header
export const CheckModulesShortcutt = (module, auth) => {

  const modulos = useSelector((state) => state.CustomizerReducer.modulosActivos); 
  const userModules = auth.authenticatedData.modulosActivos;
  const userPermissions = auth.authenticatedData && auth.authenticatedData.rol.idPermisos;
  const modulosSeguro = !modulos || modulos.length === 0 ? userModules : modulos;
    return (
      modulosSeguro?.includes(module) &&
      (module === modules.workflow || module === modules.marketplace || module === modules.controlTower || module === modules.bay
        ? true
        : PermissonByModule(auth, module).some((p) => userPermissions.includes(p)))
    ); // trae todos los permisos de cada modulo
    // despues verifica si algun permiso del usuario esta dentro de este array
}


export const CheckPermissions = (auth, permissions) =>{
    const userPermissions= auth.authenticatedData && auth.authenticatedData.rol.idPermisos;

    return userPermissions?.some(e=>permissions.includes(e));
};

export const CheckSubscrAndPerm = (auth, module, permissions) =>{
  const userModules = auth.authenticatedData.modulosActivos;
  const modulos = useSelector((state) => state.CustomizerReducer.modulosActivos); 
  const modulosSeguro = !modulos || modulos.length === 0 ? userModules : modulos;
   
    if (!module || (module && modulosSeguro?.includes(module))) {
      return CheckPermissions(auth, permissions);
    }
    return false;
}

export const NavigateTo = (auth, module) =>{
    if (!auth?.isAuthenticated) {
        return "/auth/login";
      }
    const routesByPermissions =  ModuleRoutesWithPermissions(auth, module); 
    const navigateTo = routesByPermissions.filter( r =>  r.permission.some( p => auth.authenticatedData.rol.idPermisos.includes(p)))[0];
    return navigateTo? navigateTo.route : 'inicio';
}