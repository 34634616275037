/* eslint-disable no-param-reassign */
import axios from 'axios';
import { CARGO_AI_PATH, CARGO_ORDER_AI_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getAllCargo = async () => {
  const response = await axios.get(`${CARGO_AI_PATH}/listado`);
  return response;
};

export const getCargo = async (id) => {
  const response = await axios.get(`${CARGO_AI_PATH}/ver?id=${id}`);
  return response;
};

export const createCargo = async (data) => {
  const response = await axios.post(`${CARGO_AI_PATH}/guardar`, data);
  return response;
};

export const createCargoOrder = async (data) => {
  const response = await axios.post(`${CARGO_ORDER_AI_PATH}/guardar`, data);
  return response;
};

export const getCargoOrder = async (id) => {
  const response = await axios.get(`${CARGO_ORDER_AI_PATH}/ver?id=${id}`);
  return response;
};

export const deleteCargo = async (id) => {
  const response = await axios.delete(`${CARGO_AI_PATH}/eliminar?id=${id}`);
  return response;
};
