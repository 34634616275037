import axios from 'axios';
import { FEATURES_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getFeatures = async () => {
  return axios.get(`${FEATURES_PATH}/listado`);
};

export const createFeature = async (feature) => {
  return axios.post(`${FEATURES_PATH}/guardar`, feature);
};

export const getAllFeaturesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${FEATURES_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getFeaturesListPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${FEATURES_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deleteFeature = async (id) => {
  return axios.delete(`${FEATURES_PATH}/eliminar?id=${id}`);
};
