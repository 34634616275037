import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Link,
  IconButton,
} from '@mui/material';
import {
  PictureAsPdf,
  Description,
  Image,
  Videocam,
  Audiotrack,
  InsertDriveFile,
} from '@mui/icons-material';
import { BASE_URL } from '../../../../config';

const DocumentView = ({ archivos }) => {
  const obtenerIcono = (nombreArchivo) => {
    const extension = nombreArchivo.split('.').pop().toLowerCase();

    switch (extension) {
      case 'pdf':
        return <PictureAsPdf />;
      case 'doc':
      case 'docx':
        return <Description />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <Image />;
      case 'mp4':
      case 'avi':
      case 'mov':
        return <Videocam />;
      case 'mp3':
      case 'wav':
        return <Audiotrack />;
      default:
        return <InsertDriveFile />;
    }
  };

  const abrirArchivo = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <List>
      {archivos?.map((archivo, index) => (
        <ListItem key={`-${archivo.nombre}`}>
          <ListItemAvatar>
            <IconButton onClick={() => abrirArchivo(`${BASE_URL}${archivo.url}`)}>
            <Avatar>{obtenerIcono(archivo.nombre)}</Avatar>
            </IconButton>
          </ListItemAvatar>
          <ListItemText primary={archivo.nombre} />
        </ListItem>
      ))}
    </List>
  );
}

export default DocumentView;