import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CustomFormLabel from '../../../../components/forms/custom-elements/CustomFormLabel';
import getFileIcon from '../../../fleet/MapOT/utils/getFileIcon';
import { ViewAttachment } from '../../../../api/documents';
import CustomTextField from '../../../../components/forms/custom-elements/CustomTextField';
import { createCargo } from '../../../../api/cargoia';

const FILE_SIZE = 50000000;

const LoadAIForm = ({ setShowForm, showForm, favorites, listValues, loads, setLoads }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [file, setFile] = useState(null);
  const [t] = useTranslation();
  const theme = useTheme();
  const [loadIA, setLoadIA] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = (key) => {
    closeSnackbar(key);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fecha: '',
      idEmpresa: '',
      mensajeTranscripto: '',
      pathArchivos: '',
      probabilidadDeRetorno: false,
      publicada: false,
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      for (let i = 0; i < filesToUpload.length; i++) {
        formData.append('archivos', filesToUpload[i]);
      }
      const empresa = listValues.favorites.find((f) => f.idOtraEmpresa === values.idEmpresa)
      formData.append('fecha', values.fecha);
      formData.append('idEmpresa', values.idEmpresa);
      formData.append('nombreEmpresa', empresa.contacto.nombre);
      formData.append('publicacionDirecta', empresa.contacto.publicacionDirecta || false);
      formData.append('mensajeTranscripto', values.mensajeTranscripto);
      formData.append('estado', 'PENDIENTE');
      formData.append('pathArchivos', values.pathArchivos);
      formData.append('probabilidadDeRetorno', values.probabilidadDeRetorno);
      formData.append('publicada', values.publicada);
      if(empresa.porcentajeFollowGo)
        formData.append('porcentajeFollowGo', empresa.porcentajeFollowGo.porcentaje);

      try {
        const { data } = await createCargo(formData);
        setLoadIA(data);
        setLoads([...loads, data])
        setShowForm(false);
      } catch (error) {
        console.log(error);
      }
    },
  });

  function handleAddFile(e) {
    const [fileToUpload] = e.target.files;
    if (fileToUpload.size < FILE_SIZE) {
      setFile(fileToUpload);
      formik.setFieldValue(file);
      setFilesToUpload([...filesToUpload, fileToUpload]);
    } else {
      enqueueSnackbar(t('Incidente.Alerts.El archivo debe ser menor a 50MB'), {
        variant: 'warning',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 4000,
        onClick: () => handleCloseSnackbar(),
      });
    }
  }

  const getFile = (archivo) => {
    if (loadIA) {
      const currentFiles = filesToUpload.find((f) => f.name === archivo);
      const filePath = loadIA.pathArchivos.find((a) => a.includes(archivo));
      if (currentFiles) return currentFiles;
      window.open(ViewAttachment(filePath), '_blank');
      return false;
    }
    return filesToUpload.find((f) => f.name === archivo);
  };

  function viewFile(filename) {
    const fileFound = getFile(filename);

    if (!fileFound) return;

    const objUrl = URL.createObjectURL(fileFound);

    if (filename.match(/^.*\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
      const image = new Image();
      image.src = objUrl;
      const w = window.open('about:blank');
      setTimeout(() => {
        w.document.write(image.outerHTML);
      }, 0);
    } else {
      const newWindow = window.open('about:blank');
      setTimeout(() => {
        newWindow.location.href = objUrl;
      }, 0);
    }
  }

  function removeFile(fileToRemove) {
    setFilesToUpload(filesToUpload.filter((f) => f.name !== fileToRemove));

    const filepathToRemove = loadIA?.pathArchivos?.find((a) => a.includes(fileToRemove));
    if (filepathToRemove && !filesToRemove.some((f) => f.includes(fileToRemove)))
      setFilesToRemove([...filesToRemove, filepathToRemove]);
  }

  return (
    <Dialog open={showForm} maxWidth="md">
      <DialogTitle sx={{ ml: 2 }}>{t('Crear Carga IA')}</DialogTitle>
      <DialogContent>
        <Grid item sm={12}>
          <Grid container >
            <Grid xs={6} key="empresaDadora">
            <CustomFormLabel htmlFor="Backoffice.Dashboard.empresaDadora">{`${t(
                'Backoffice.Dashboard.empresaDadora',
              )}*`}</CustomFormLabel>
              <Autocomplete
                disablePortal
                options={favorites}
                onChange={(_, newValue) => formik.setFieldValue('idEmpresa', newValue.id)}
                isOptionEqualToValue={(option, value) => option.id === value || option.id === value.id}
                value={
                  formik?.values?.idEmpresa
                    ? favorites.find((option) => option.id === formik?.values?.idEmpresa)
                    : null
                }
                renderInput={(params) => (
                  <TextField {...params} label={t('Backoffice.Dashboard.empresaDadora')} />
                )}
              />
            </Grid>
            <Grid xs={6} key="fecha">
              <Grid >
                  <CustomFormLabel htmlFor='retiroDateTimeRangeSelector'>
                    {t('Marketplace.fecha')}
                  </CustomFormLabel>
                </Grid>
               <DateTimePicker
                  label={t("Marketplace.fecha")}
                  fullWidth
                  sx={{ width: '100%' }}
                  value={formik.values.fechaDeCarga || null }
                  onChange={(e) => formik.setFieldValue("fecha", e)}
                  renderInput={(props) => <TextField {...props} />}
                  minDateTime={new Date()}
                  format="dd/MM/yyyy HH:mm"
                  />
              </Grid>
            <Grid xs={6} key="probabilidadDeRetorno">
              <CustomFormLabel htmlFor="probabilidadDeRetorno">{`${t(
                'probabilidadDeRetorno',
              )}*`}</CustomFormLabel>
              <FormControl
              >
                <FormControlLabel
                  control={<Checkbox checked={formik.values.probabilidadDeRetorno} 
                  onChange={(event) =>
                    formik.setFieldValue('probabilidadDeRetorno', event.target.checked)}
                  />}
                  label="probabilidad De Retorno"
                />
              </FormControl>
            </Grid>
            <Grid xs={6} key="publicada">
              <CustomFormLabel htmlFor="publicada">{`${t('publicada')}*`}</CustomFormLabel>
              <FormControl>
                <FormControlLabel
                  control={<Checkbox checked={formik.values.publicada} 
                  onChange={(event) => formik.setFieldValue('publicada', event.target.checked)}/>}
                  label="Publicada"
                />
              </FormControl>
            </Grid>
            <Grid xs={12} key="mensajeTranscripto">
              <CustomFormLabel htmlFor="mensajeTranscripto">Mensaje original </CustomFormLabel>
              <CustomTextField
                id="mensajeTranscripto"
                name="mensajeTranscripto"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.mensajeTranscripto || ''}
                error={Boolean(
                  formik.touched.mensajeTranscripto && formik.errors.mensajeTranscripto,
                )}
                helperText={formik.touched.mensajeTranscripto && formik.errors.mensajeTranscripto}
                variant="outlined"
                fullWidth
                autoComplete="off"
                multiline
              />
            </Grid>
            <Grid sm={6}>
              <CustomFormLabel
                sx={{
                  height: 1,
                  mt: 0,
                  letterSpacing: '2px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('Documentation.Attached files')}*:
              </CustomFormLabel>
            </Grid>
            
            <Grid
              sm={12}
              // sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              <IconButton
                sx={{ '&:hover': { color: theme.palette.primary.main } }}
                component="label"
              >
                <FeatherIcon icon="file-plus" />
                <Typography ml={1}>{t('Documentation.Add file')}</Typography>
                <input key={Date.now()} type="file" hidden onChange={handleAddFile} />
              </IconButton>
            </Grid>
            <Grid sm={12} mt={0} pt={0}>
              {loadIA && loadIA?.pathArchivos?.length === 0 && (
                <Typography>{t('Documentation.No attachments added')}</Typography>
              )}

              {loadIA &&
                loadIA?.pathArchivos?.map((archivo, index) => (
                  <Grid container key={`file-${archivo}-${index.toString()}`}>
                    <Grid xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                      {getFileIcon(archivo)}
                    </Grid>
                    <Grid xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>{archivo}</Typography>
                    </Grid>
                    <Grid xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        sx={{ '&:hover': { color: 'crimson' } }}
                        onClick={() => removeFile(archivo)}
                      >
                        <FeatherIcon icon="x" />
                      </IconButton>
                    </Grid>
                    <Grid xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        sx={{ '&:hover': { color: theme.palette.primary.main } }}
                        onClick={() => viewFile(archivo)}
                      >
                        <FeatherIcon icon="eye" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={() => setShowForm(false)}
        >
          {t('Configuration.Cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          type="submit"
          onClick={formik.handleSubmit}
        >
          {t('Configuration.Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadAIForm;
