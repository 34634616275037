import React, { useEffect } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

const IntroCard = ({ company }) => {
  const [t] = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography fontWeight="500" variant="h3">
          {t('Loads.Favorites.Summary')}
        </Typography>
        <Grid container spacion={1}>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            display={
              company.identificadorFiscal && company.identificadorFiscal.length > 0
                ? 'flex'
                : 'none'
            }
            alignItems="flex-start"
            sx={{
              mt: 3,
            }}
          >
            <Typography
              display="flex"
              alignItems="flex-start"
              sx={{
                color: (theme) => theme.palette.grey.A200,
              }}
            >
              <FeatherIcon icon="info" width="20" display="flex" alignitems="center" />
            </Typography>
            <Box
              sx={{
                ml: '20px',
              }}
              display="flex"
            >
              <Typography
                component="span"
                color="textSecondary"
                variant="h5"
                fontWeight="400"
                sx={{
                  mr: 1,
                }}
              >
                {t('Configuration.Fiscal Identifier')}
              </Typography>
              <Typography variant="h5" fontWeight="600">
                {company.identificadorFiscal}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            display={company.mail && company.mail.length > 0 ? 'flex' : 'none'}
            alignItems="flex-start"
            sx={{
              mt: 3,
              mr: 2,
            }}
          >
            <Typography
              display="flex"
              alignItems="flex-start"
              sx={{
                color: (theme) => theme.palette.grey.A200,
              }}
            >
              <FeatherIcon icon="mail" width="20" display="flex" alignitems="center" />
            </Typography>
            <Box
              sx={{
                ml: '20px',
              }}
            >
              <Typography variant="h5" fontWeight="600">
                {company.mail}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            display={company.telefono && company.telefono.length > 0 ? 'flex' : 'none'}
            alignItems="flex-start"
            sx={{
              mt: 2,
            }}
          >
            <Typography
              display="flex"
              alignItems="flex-start"
              sx={{
                color: (theme) => theme.palette.grey.A200,
              }}
            >
              <FeatherIcon icon="phone" width="20" display="flex" alignitems="center" />
            </Typography>
            <Box
              sx={{
                ml: '20px',
              }}
            >
              <Typography variant="h5" fontWeight="600">
                {company.telefono}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            display="flex"
            alignItems="flex-start"
            sx={{
              mt: 2,
            }}
          >
            {company?.ubicacion?.provincia && (
              <>
                <Typography
                  display="flex"
                  alignItems="flex-start"
                  sx={{
                    color: (theme) => theme.palette.grey.A200,
                  }}
                >
                  <FeatherIcon icon="home" width="20" display="flex" alignitems="center" />
                </Typography>
                <Box
                  sx={{
                    ml: '20px',
                  }}
                >
                  <Typography variant="h5" fontWeight="600">
                    {`${company?.ubicacion?.provincia}, 
              ${company.pais.nombre}`}
                  </Typography>
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={4} key={`ubicacion-${company?.ubicacion?.id}`}>
            <Box
              display="flex"
              alignItems="flex-start"
              sx={{
                mt: 2,
              }}
            >
              <Typography
                display="flex"
                alignItems="flex-start"
                sx={{
                  color: (theme) => theme.palette.grey.A200,
                }}
              >
                <FeatherIcon icon="map-pin" width="20" display="flex" alignitems="center" />
              </Typography>
              <Box
                sx={{
                  ml: '20px',
                }}
              >
                <Typography variant="h5" fontWeight="600">
                  {company?.ubicacion?.referencia && company?.ubicacion?.referencia.length > 0 && (
                    <Typography
                      component="span"
                      color="textSecondary"
                      variant="h5"
                      fontWeight="400"
                      sx={{
                        mr: 1,
                      }}
                    >
                      {company?.ubicacion?.referencia}
                    </Typography>
                  )}
                  {`${company?.ubicacion?.direccion}`}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default IntroCard;
