import { getLoadTypes } from '../../../../api/loadType';
import { getPackagings } from '../../../../api/packaging';
import { getCustomers } from '../../../../api/customer';
import { getCurrencies } from '../../../../api/currency';
import { getFeatures } from '../../../../api/features';
import { getPaymentConditions } from '../../../../api/paymentConditions';
import { getTrailerTypes } from '../../../../api/trailerType';
import { getVehicleTypes } from '../../../../api/vehicleType';
import { getTruckTypes } from '../../../../api/truckType';
import { getPalletTypes } from '../../../../api/pallet';
import { getProducts } from '../../../../api/product';
import { getFavorites, getOnlyActiveFavorites } from '../../../../api/favorite';
import { getCountries } from '../../../../api/country';
import { getAccessories } from '../../../../api/accessories';
import { getServiceTypes } from '../../../../api/enums';
import { getTemplatesList } from '../../../../api/textTemplate';
import { getDocumentTypes } from '../../../../api/tipoDocumento';
import { getTaxes } from '../../../../api/taxes';

export const getList = async () => {
  const [
    loadTypesResponse,
    packagingResponse,
    palletTypesResponse,
    customersResponse,
    featuresResponse,
    trailerTypesResponse,
    vehicleTypesResponse,
    truckTypesResponse,
    currencyResponse,
    paymentConditionsResponse,
    listOfProducts,
    favoritesResponse,
    countryResponse,
    accessoriesResponse,
    serviceTypeResponse,
    templatesResponse,
    documentTypesResponse,
  ] = await Promise.all([
    getLoadTypes(),
    getPackagings(),
    getPalletTypes(),
    getCustomers(),
    getFeatures(),
    getTrailerTypes(),
    getVehicleTypes(),
    getTruckTypes(),
    getCurrencies(),
    getPaymentConditions(),
    getProducts(),
    getOnlyActiveFavorites(),
    getCountries(),
    getAccessories(),
    getServiceTypes(),
    getTemplatesList(),
    getDocumentTypes(),
  ]);

  const list = {
    loadTypes: loadTypesResponse.data || [],
    packaging: packagingResponse.data || [],
    palletType: palletTypesResponse.data || [],
    customers: customersResponse.data.content || [],
    features: featuresResponse.data || [],
    trailerTypes: trailerTypesResponse.data || [],
    vehicleTypes: vehicleTypesResponse.data || [],
    truckTypes: truckTypesResponse.data || [],
    typesOfCurrency: currencyResponse.data || [],
    currency: currencyResponse.data || [],
    paymentConditions: paymentConditionsResponse.data || [],
    listOfProducts: listOfProducts.data || [],
    favorites: favoritesResponse || [],
    country: countryResponse.data || [],
    accessories: accessoriesResponse.data || [],
    serviceType:
      Object.entries(serviceTypeResponse.data).map(([key, value]) => ({
        value: key,
        label: value,
      })) || [],
    templatesList:
      templatesResponse.data.map((template) => ({
        label: template.nombre,
        value: template.id,
      })) || [],
    documentTypes: documentTypesResponse.data || [],
  };

  return list;
};

export const getListSimple = async () => {
  const [
    loadTypesResponse,
    packagingResponse,
    palletTypesResponse,
    trailerTypesResponse,
    vehicleTypesResponse,
    truckTypesResponse,
    currencyResponse,
    favoritesResponse,
    paymentConditionsResponse,
    countryResponse,
    serviceTypeResponse,
    taxesResponse
  ] = await Promise.all([
    getLoadTypes(),
    getPackagings(),
    getPalletTypes(),
    getTrailerTypes(),
    getVehicleTypes(),
    getTruckTypes(),
    getCurrencies(),
    getFavorites(),
    getPaymentConditions(),
    getCountries(),
    getServiceTypes(),
    getTaxes()
  ]);

  const list = {
    loadTypes: loadTypesResponse.data || [],
    packaging: packagingResponse.data || [],
    palletType: palletTypesResponse.data || [],
    trailerTypes: trailerTypesResponse.data || [],
    vehicleTypes: vehicleTypesResponse.data || [],
    truckTypes: truckTypesResponse.data || [],
    typesOfCurrency: currencyResponse.data || [],
    currency: currencyResponse.data || [],
    favorites: favoritesResponse.data || [],
    paymentConditions: paymentConditionsResponse.data || [],
    country: countryResponse.data || [],
    serviceType:
      Object.entries(serviceTypeResponse.data).map(([key, value]) => ({
        value: key,
        label: value,
      })) || [],
      taxes: taxesResponse.data || []
  };

  return list;
};
