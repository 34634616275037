import React from 'react';
import { Box, Typography, IconButton, useMediaQuery, Drawer, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Scrollbar from './Scrollbar';

const LateralModal = ({ open, handleClose, children, title, sx }) => {
  const theme = useTheme();

  const xlUp = useMediaQuery((th) => th.breakpoints.up('xl'));
  const lgUp = useMediaQuery((th) => th.breakpoints.up('lg'));
  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'));
  const smUp = useMediaQuery((th) => th.breakpoints.up('sm'));

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      anchor="right"
      sx={sx}
    >
      <Box
        sx={{
          // position: 'absolute',
          // top: '0',
          // right: 0,

          width: xlUp ? '35vw' : lgUp ? '45vw' : mdUp ? '60vw' : smUp ? '75vw' : '100vw',
          height: '100%',
          // bgcolor: 'background.paper',
          backgroundColor: theme.palette.background.paper,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '100%',
            borderBottom: `10px solid ${theme.palette.primary.main}`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: !smUp ? 2 : 0,
          }}
        >
          <Typography variant="h2">{title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ height: '100%', width: '100%', py: 3, px: !smUp ? 2 : 0 }}>
          <Scrollbar>{children}</Scrollbar>
        </Box>
      </Box>
    </Drawer>
  );
};

LateralModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  children: PropTypes.node,
};

export default LateralModal;
